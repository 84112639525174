import {
    IStep,
    IWorkflowDesign,
    SystemStepId,
    WorkflowActionService,
    WorkflowGraphService,
} from "@visoryplatform/workflow-core";
import { IWorkflowVariation, WorkflowTokenResolver } from "@visoryplatform/threads";
import { map, switchMap } from "rxjs/operators";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { WorkflowService } from "./workflow.service";

@Injectable({
    providedIn: "root",
})
export class WorkflowEvaluationService {
    constructor(private workflowService: WorkflowService) {}

    getWorkflowDesign(designId: string, workflowTokens: IWorkflowVariation[]): Observable<IWorkflowDesign> {
        return this.workflowService.getDesign(designId).pipe(
            map((design) => WorkflowTokenResolver.resolveWorkflowTokens(workflowTokens, design)),
            switchMap(({ resolvedDesign }) => WorkflowActionService.evaluateWorkflow(resolvedDesign)),
            map((workflow) => {
                const steps = this.orderAllWorkflowSteps(workflow);
                return { ...workflow, steps: Object.fromEntries(steps.map((step) => [step.id, step])) };
            }),
        );
    }
    private orderAllWorkflowSteps(workflow: IWorkflowDesign): IStep[] {
        const steps = WorkflowGraphService.orderWorkflowSteps(workflow);
        const missingSystemSteps = Object.values(SystemStepId).map((systemStepId) => {
            if (!steps[systemStepId]) {
                return { ...workflow.steps[systemStepId], id: systemStepId };
            } else {
                return null;
            }
        });

        return [...steps, ...missingSystemSteps].filter((step) => !!step);
    }
}
