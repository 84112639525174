import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ENVIRONMENT } from "src/app/injection-token";
import { InternalRoles, Role, ThreadFilterSources, ThreadFilters } from "@visoryplatform/threads";
import { environmentCommon, EnvironmentSpecificConfig } from "../../environment/environment.common";
import { Observable } from "rxjs";
import qs from "qs";

@Injectable({
    providedIn: "root",
})
export class ThreadFilterService {
    constructor(
        private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    getFilteredThreads<T>(
        source: ThreadFilterSources,
        filterValues?: ThreadFilters,
        includeAll?: boolean,
        userId?: string,
    ): Observable<T[]> {
        const { base } = this.environment.threadsEndpoints;
        const { threads, filters } = environmentCommon.threadsEndpoints;

        const searchParams = qs.stringify(
            { ...filterValues, includeAll, userId },
            { arrayFormat: "comma", encode: false },
        );
        const url = `${base}${threads}${filters}/${source}?${searchParams}`;

        return this.http.get<T[]>(url);
    }

    getAssigneeFilterSource(role: Role): ThreadFilterSources {
        return InternalRoles.includes(role) ? "internalStepAssignees" : "externalStepAssignees";
    }
}
