import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { IThread, Role, WorkflowDueDateService, WorkflowStepType } from "@visoryplatform/threads";
import { ENVIRONMENT } from "src/app/injection-token";
import {
    IWorkflow,
    IStep,
    IWorkflowAction,
    SystemStepId,
    AssigneeExtensionHelpers,
    WorkTimeExtensionHelpers,
} from "@visoryplatform/workflow-core";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { IWorkflowStepUI } from "../../../threads-ui/interfaces/IWorkflowStepUI";
import { WorkflowTextConstants } from "../../constants/workflow-text.constants";
import { LaunchDarklyFeatureFlags } from "../../../feature-flags";
import { FeatureFlagService } from "../../../feature-flags";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PermissionService } from "../../../threads-ui/services/permissions.service";
import { DurationBadgeColour } from "../duration-badge/duration-badge.component";
import { VAvatar } from "@visoryplatform/portal-ui";
import { UserAvatarService } from "../../../threads-ui/services/user-avatar.service";

@Component({
    selector: "workflow-step-entry",
    templateUrl: "./workflow-step-entry.component.html",
    styleUrls: ["./workflow-step-entry.component.scss"],
})
export class WorkflowStepEntryComponent implements OnChanges {
    @Input() entry: IWorkflowStepUI;
    @Input() workflow: IWorkflow;
    @Input() thread: IThread;
    @Input() role: Role;
    @Input() accountLabel: string;
    @Input() index: number;
    @Input() isLastMilestone: boolean;
    @Input() timezone: string;

    @Output() performAction = new EventEmitter<IWorkflowAction>();

    readonly stepTypes = WorkflowStepType;
    readonly theme = this.environment.theme;
    readonly SystemStepId = SystemStepId;
    readonly FEATURE_FLAGS = LaunchDarklyFeatureFlags;

    avatars$: Observable<VAvatar[]>;

    workTime$: Observable<number | null>;
    slaTime$: Observable<number>;
    slaColour$: Observable<DurationBadgeColour>;

    hasDueDates: boolean;
    roles = Role;
    selectedStep: IStep;
    stateChanged = false;
    text = WorkflowTextConstants;
    timeZone: string;
    assignees: string[];

    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private permissionService: PermissionService,
        private featureFlagService: FeatureFlagService,
        private userAvatarService: UserAvatarService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        const { entry, role } = changes;

        if (entry) {
            this.hasDueDates = WorkflowDueDateService.isSla(this.entry?.step);
            this.assignees = AssigneeExtensionHelpers.getAssigneeData(this.entry?.step?.extensions)?.assignees || [];
            this.avatars$ = this.userAvatarService.getAvatarsFromIds(this.assignees);
        }

        if (entry || role) {
            this.workTime$ = this.getWorkTime(this.entry?.step, this.role);
        }
    }

    selectStepAt(action: IWorkflowAction): void {
        this.performAction.emit(action);
    }

    private getWorkTime(step: IStep, role: Role): Observable<number | null> {
        const hasWorkTimeViewPermission$ = this.permissionService.checkPermissions(role, "ViewWorkTime");
        const hasWorkTimeFeatureEnabled$ = this.featureFlagService.getFlag(
            LaunchDarklyFeatureFlags.EnableWorkTimeOnWorkflowStepper,
        );

        const canViewWorkTime$ = combineLatest([hasWorkTimeViewPermission$, hasWorkTimeFeatureEnabled$]).pipe(
            map(
                ([hasWorkTimeViewPermission, hasWorkTimeFeatureEnabled]) =>
                    hasWorkTimeViewPermission && hasWorkTimeFeatureEnabled,
            ),
        );

        return canViewWorkTime$.pipe(
            map((canViewWorkTime) => {
                if (canViewWorkTime) {
                    return WorkTimeExtensionHelpers.getMultipliedWorkTime(step?.extensions);
                } else {
                    return null;
                }
            }),
        );
    }
}
