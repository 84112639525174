<div class="request-common-request-message-view">
    <div
        class="request-common-request-message-view-field"
        [ngClass]="{ 'request-common-request-message-view-field--internal': isInternal && card?.isInternal }"
    >
        @if (!canEditDescription) {
            <quill-view-wrapper
                [content]="card?.description"
                ariaLabel="message"
            >
            </quill-view-wrapper>
        } @else {
            <quill-editor-wrapper
                [formControl]="cardDescription"
                [threadType]="threadType"
                [mentionableUsers]="participants | participantsToMention | async"
                [inline]="true"
            >
            </quill-editor-wrapper>
        }
    </div>

    <thread-card-replies
        #threadCardRepliesComponent
        [showCompose]="focusReplyInput"
        [replies]="replies"
        [card]="card"
        [thread]="thread"
        [userId]="userId$ | async"
        [readonly]="card?.status === cardStatuses.Disabled"
        [replyControl]="replyMessage"
        [isInternal]="isInternal && card?.isInternal"
        (replySaved)="replySaved()"
    >
    </thread-card-replies>
</div>
