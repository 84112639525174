import { Component, Injector, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
    CardStatus,
    CheckResult,
    CheckSource,
    IThreadCard,
    ITimeline,
    TransformerCardState,
} from "@visoryplatform/threads";
import { DateTime, Duration } from "luxon";
import { LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags/enums/LaunchDarklyFeatureFlags";
import { DialogRef, DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { combineLatest, forkJoin, Observable } from "rxjs";
import { filter, map, mapTo, switchMap, take } from "rxjs/operators";
import { AuthService } from "../../../../portal-modules/src/lib/findex-auth";
import {
    RequestActionButtonLabel,
    RequestStatuses,
} from "../../../vault/components/request/constants/request.constants";
import { IRequestAnalyticsTags } from "../../../vault/components/request/interfaces/IRequestAnalyticsTags";
import { IRequestForm } from "../../../vault/components/request/interfaces/IRequestForms";
import { TransformersService } from "../../services/transformers.service";
import { ITransformerModalData } from "../../types/ITransformerModalData";
import { AlertService } from "@visoryplatform/portal-ui";
import { ToastSeverity } from "projects/portal-modules/src/lib/shared/constants/toast.constants";
import { TransformerResultsModalComponent } from "../transformer-results-modal/transformer-results-modal.component";

@Component({
    selector: "transformer-action",
    templateUrl: "./transformer-action.component.html",
    styleUrls: ["./transformer-action.component.scss"],
})
export class TransformerActionComponent implements OnInit {
    readonly CARD_STATUSES = CardStatus;
    readonly buttonLabels = RequestActionButtonLabel;
    readonly requestStatuses = RequestStatuses;
    readonly FEATURE_FLAGS = LaunchDarklyFeatureFlags;

    analyticsTags: IRequestAnalyticsTags;
    card$: Observable<IThreadCard>;
    form$: Observable<FormGroup<IRequestForm>>;
    state$: Observable<TransformerCardState>;
    thread$: Observable<ITimeline>;
    userId$: Observable<string>;
    allChecksPass$: Observable<boolean>;

    loader = new Loader();

    public modalData: ITransformerModalData;
    public dialogRef: DialogRef;

    constructor(
        private authService: AuthService,
        private transformService: TransformersService,
        private injector: Injector,
        private dialogService: DialogService,
        private alertService: AlertService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.dialogRef = await this.dialogService.getRef(this.injector).pipe(take(1)).toPromise();
        this.modalData = await this.dialogService
            .getData<ITransformerModalData>(this.injector)
            .pipe(take(1))
            .toPromise();

        this.thread$ = this.modalData.thread$;
        this.card$ = this.modalData.card$;
        this.state$ = this.modalData.state$;
        this.userId$ = this.authService.getUserId();

        this.allChecksPass$ = this.state$.pipe(map((state) => !!state.isCompleted));

        const runSources$ = combineLatest([this.thread$, this.state$]).pipe(
            take(1),
            filter(([_, state]) => !state.isCompleted),
            switchMap(([thread, state]) => this.runSources(thread.id, state.sources)),
        );

        runSources$.subscribe();
    }

    trackTransformerResult(_index: number, transformerResult: CheckResult): string {
        return transformerResult?.resultTransformId;
    }

    refreshData(threadId: string, sources: CheckSource[]): void {
        this.runSources(threadId, sources)
            .pipe(
                take(1),
                switchMap(() => {
                    return this.alertService.show({
                        status: ToastSeverity.Success,
                        label: "Quality checks refreshing",
                        message: "It may take a few seconds for the quality check results to refresh.",
                    });
                }),
            )
            .subscribe();
    }

    viewResults(transformer: CheckResult): void {
        this.dialogService.open(TransformerResultsModalComponent, { data: { transformer } }).pipe(take(1)).subscribe();
    }

    private runSources(threadId: string, sources: CheckSource[]): Observable<void> {
        console.log("running sources", threadId, sources);
        const periodEnd = DateTime.now();
        return forkJoin(sources.map((source) => this.runSource(threadId, source, periodEnd))).pipe(mapTo(null));
    }

    private runSource(threadId: string, source: CheckSource, periodEnd: DateTime): Observable<void> {
        const duration = Duration.fromISO(source.duration);
        const periodStart = periodEnd.minus(duration);

        const event = {
            periodStart: periodStart.toISO(),
            periodEnd: periodEnd.toISO(),
        };

        return this.loader.wrap(this.transformService.runSource(threadId, source.id, event));
    }
}
