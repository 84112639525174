import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui";
import { SharedModule } from "projects/portal-modules/src/lib/shared";
import { ThreadsUiModule } from "projects/portal-modules/src/lib/threads-ui";
import { PLUGIN_FACTORY } from "projects/portal-modules/src/lib/plugins";
import { VaultModule } from "../vault/vault.module";
import { FxTodoListModule } from "projects/portal-modules/src/lib/fx-todolist/fx-todolist.module";
import { InlineEditModule } from "../vault/components/inline-edit/inline-edit.module";
import { QuillModule } from "ngx-quill";
import { CopilotPlugin } from "./copilot.plugin";
import { TransactionsActionComponent } from "./components/transactions-action/transactions-action.component";
import { TransactionCardComponent } from "./components/transactions-card/transactions-card.component";
import { EditTransactionQueriesComponent } from "./components/edit-transaction-queries/edit-transactions-queries.component";
import { UpdateTransactionQueriesComponent } from "./components/update-transaction-queries/update-transactions-queries.component";
import { CreateTransactionQueriesComponent } from "./components/create-transaction-queries/create-transactions-queries.component";
import { TransactionListComponent } from "./components/transaction-list/transaction-list.component";
import { CopilotService } from "./services/copilot.service";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { QueryFieldComponent } from "./components/query-field/query-field.component";
import { AnswerFieldComponent } from "./components/answer-field/answer-field.component";
import {
    TuiScrollbarModule,
    TuiButtonModule,
    TuiLoaderModule,
    TuiLabelModule,
    TuiErrorModule,
    TuiDataListModule,
} from "@taiga-ui/core";
import {
    TuiAccordionModule,
    TuiDataListWrapperModule,
    TuiFieldErrorPipeModule,
    TuiFilesModule,
    TuiInputFilesModule,
    TuiInputModule,
    TuiIslandModule,
    TuiSelectModule,
} from "@taiga-ui/kit";
import { FileInputModule } from "../vault/components/file-input/file-input.module";
import { EmptyStateModule } from "projects/portal-modules/src/lib/empty-state/empty-state.module";
import { MemoriesModule } from "../memories/memories.module";
import { FxTableModule } from "projects/portal-modules/src/lib/fx-table/fx-table.module";
import { PortalUiModule } from "@visoryplatform/portal-ui";

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FxUiModule,
        ThreadsUiModule,
        FindexUiModule,
        RouterModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        VaultModule,
        FxTodoListModule,
        QuillModule,
        InlineEditModule,
        MatFormFieldModule,
        MatDatepickerModule,
        EmptyStateModule,

        MemoriesModule,
        FileInputModule,
        TuiScrollbarModule,
        TuiInputFilesModule,
        TuiInputModule,
        TuiFilesModule,
        TuiButtonModule,
        TuiLoaderModule,
        TuiLabelModule,
        TuiIslandModule,
        TuiSelectModule,
        TuiAccordionModule,
        TuiFieldErrorPipeModule,
        TuiErrorModule,
        TuiDataListWrapperModule,
        TuiDataListModule,
        FxTableModule,
        PortalUiModule,
    ],
    exports: [QueryFieldComponent],
    declarations: [
        TransactionsActionComponent,
        TransactionCardComponent,
        EditTransactionQueriesComponent,
        CreateTransactionQueriesComponent,
        UpdateTransactionQueriesComponent,
        TransactionListComponent,
        QueryFieldComponent,
        AnswerFieldComponent,
    ],
    providers: [{ provide: PLUGIN_FACTORY, useClass: CopilotPlugin, multi: true }, CopilotService],
})
export class CopilotModule {}
