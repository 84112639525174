import { Component, Input, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { IWorkflow } from "@visoryplatform/workflow-core";
import { Observable, timer, BehaviorSubject } from "rxjs";
import { map, startWith, switchMap, debounceTime } from "rxjs/operators";
import { VBadgeColors } from "@visoryplatform/portal-ui";
import { DueDateStatus, getWorkflowStatus } from "@visoryplatform/threads";

export type DueDateStatusIndicator = {
    status: DueDateStatus;
    color: VBadgeColors;
    tooltip: string;
};

const ONE_MINUTE_MS = 60000;
const DEBOUNCE_TIME_MS = 2500;

@Component({
    selector: "workflow-status-indicator",
    templateUrl: "./workflow-status-indicator.component.html",
    styleUrls: ["./workflow-status-indicator.component.scss"],
})
export class WorkflowStatusIndicatorComponent implements OnInit, OnChanges {
    @Input() workflow: IWorkflow;
    readonly badgeColors = VBadgeColors;
    dueDateStatus$: Observable<DueDateStatusIndicator>;
    private refreshSubject = new BehaviorSubject<void>(undefined);

    ngOnInit(): void {
        this.dueDateStatus$ = this.refreshSubject.pipe(
            switchMap(() =>
                timer(0, ONE_MINUTE_MS).pipe(
                    startWith(0),
                    debounceTime(DEBOUNCE_TIME_MS), // Debounce as there can be multiple changes to the workflow in a short period of time
                    map(() => this.getDueDateStatusWithColor()),
                ),
            ),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.workflow) {
            this.refreshSubject.next();
        }
    }

    private getDueDateStatusWithColor(): DueDateStatusIndicator {
        const status = this.getDueDateStatus();
        const { color, tooltip } = this.getColorForStatus(status);
        return { status, color, tooltip };
    }

    private getDueDateStatus(): DueDateStatus {
        try {
            return getWorkflowStatus(this.workflow);
        } catch (error) {
            console.error(error);
            return DueDateStatus.ERROR as DueDateStatus;
        }
    }

    private getColorForStatus(status: DueDateStatus): Partial<DueDateStatusIndicator> {
        switch (status) {
            case DueDateStatus.OVERDUE:
                return { color: VBadgeColors.deepOrange, tooltip: "This workflow is overdue" };
            case DueDateStatus.BEHIND:
                return { color: VBadgeColors.yellow, tooltip: "This workflow is behind schedule" };
            case DueDateStatus.AT_RISK:
                return { color: VBadgeColors.orange, tooltip: "This workflow is at risk of being overdue" };
            case DueDateStatus.ON_TRACK:
                return { color: VBadgeColors.green, tooltip: "This workflow is on track" };
            case DueDateStatus.CANCELLED:
                return { color: VBadgeColors.grey, tooltip: "This workflow has been canceled" };
            case DueDateStatus.COMPLETED:
                return { color: VBadgeColors.grey, tooltip: "This workflow has been completed" };
            case DueDateStatus.LATE:
                return { color: VBadgeColors.deepOrange, tooltip: "This workflow was finished past the due date" };
            case DueDateStatus.ON_TIME:
                return { color: VBadgeColors.green, tooltip: "This workflow was finished on time" };
            case DueDateStatus.ERROR:
                return {
                    color: VBadgeColors.deepOrange,
                    tooltip: "There was an error calculating the status of this workflow",
                };
        }
    }
}
