<ng-container *ngIf="dueDateStatus$ | async as dueDateStatus">
    <div class="workflow-status-indicator">
        <v-indicator [color]="dueDateStatus.color">
            {{ dueDateStatus.status }}
        </v-indicator>

        <i
            class="las la-exclamation-circle"
            [tuiHint]="dueDateStatus.tooltip"
        >
        </i>
    </div>
</ng-container>
