<div class="mobile-toggles">
    <div class="mobile-toggles-filters">
        <button
            (click)="toggleFilterVisible()"
            [ngClass]="{
                'mobile-toggles-filters-active': filtersActive(),
                'mobile-toggles-filters-inactive': !filtersActive(),
            }"
        >
            <i class="las la-filter"></i>
            <span>Filter</span>
        </button>
        @if (activeFilterCount()) {
            <div class="mobile-toggles-filters-count">
                @if (activeFilterCount() > 0) {
                    {{ activeFilterCount() }}
                }
            </div>
        }
    </div>
    <div class="mobile-toggles-sort">
        @if (sortApplied()) {
            <div class="mobile-toggles-sort-count">1</div>
        }
        <button
            (click)="toggleSortVisible()"
            [ngClass]="{
                'mobile-toggles-sort-active': sortActive(),
                'mobile-toggles-sort-inactive': !sortActive(),
            }"
            [disabled]="!sortEnabled()"
        >
            <i class="las la-signal"></i> Sort
        </button>
    </div>
</div>

@if (filtersVisible()) {
    <div class="filters-content">
        <ng-content select="[table-filters]"></ng-content>
    </div>
}

<div class="mobile-actions">
    <button
        class="mobile-actions-clear"
        [ngClass]="{
            'mobile-actions-clear-hidden': !filtersVisible(),
        }"
        (click)="resetFilters()"
    >
        <i class="las la-sync-alt la-lg"></i>
        <span>Reset filters</span>
    </button>

    @if (closeVisible()) {
        <button
            class="fx-btn fx-btn--primary fx-btn--medium mobile-actions-close"
            [ngClass]="{
                'mobile-actions-close-hidden': !filtersVisible(),
            }"
            type="button"
            (click)="closeActions()"
        >
            <i class="las la-times mobile-actions-close-icon"></i>
            <span>Close</span>
        </button>
    }
</div>
