import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ITimeline, Role, NotificationHelperService } from "@visoryplatform/threads";
import { combineLatest, Observable, of, Subscription } from "rxjs";
import { distinctUntilChanged, filter, map, mapTo, shareReplay, startWith, switchMap } from "rxjs/operators";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { Environment } from "../../../environment/environment.common";
import { ENVIRONMENT, THREAD_LIBRARY } from "src/app/injection-token";
import { ThreadsWebsocketService } from "../../../shared/services/threads-websocket.service";
import { ViewExtension } from "../../../plugins/services/Libraries";
import { NotificationsService } from "projects/notifications-frontend/src/services/notifications.service";
import { DialogService } from "../../../shared/services/dialog.service";
import { LaunchDarklyFeatureFlags } from "../../../feature-flags/enums/LaunchDarklyFeatureFlags";
import { ILibrary } from "../../../plugins";
import { ICreateCardEvent } from "../../../threads-ui/components/create-card/create-card.component";
import { THREAD, ROLE } from "../../../threads-ui/interfaces/IUiCard";
import { PermissionService } from "../../../threads-ui/services/permissions.service";
import { ThreadsService } from "../../../threads-ui/services/threads.service";

@Component({
    selector: "thread-route",
    templateUrl: "./thread-route.component.html",
    styleUrls: ["thread-route.component.scss"],
})
export class ThreadRouteComponent implements OnInit, OnDestroy {
    @ViewChild("threadsContainer") threadsContainer: ElementRef;

    readonly FEATURE_FLAGS = LaunchDarklyFeatureFlags;

    currentThread$: Observable<ITimeline>;
    referencedThread$: Observable<ITimeline>;
    cardId$: Observable<string>;
    globalRole$: Observable<Role>;
    userId$: Observable<string>;
    currentRole$: Observable<Role>;
    threadExtensions$: Observable<ViewExtension[]>;
    extension$: Observable<ViewExtension>;
    currentPath$: Observable<string>;
    roles = Role;
    loader = new Loader();
    createLoader = new Loader();
    contextEnabled = this.environment.featureFlags.closeThreadContextMenu;

    observeThreadSubscription: Subscription;
    markWorkflowStepsAsSeenSubscription: Subscription;
    observingThreadId: string;

    private threadId$: Observable<string>;

    constructor(
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private websocketService: ThreadsWebsocketService,
        private router: Router,
        private threadsService: ThreadsService,
        private permissionService: PermissionService,
        private notificationsService: NotificationsService,
        private dialog: DialogService,
        @Inject(THREAD_LIBRARY) private threadLibrary: ILibrary<ViewExtension>,
        @Inject(ENVIRONMENT) private environment: Environment,
        @Inject(THREAD) private thread$: Observable<ITimeline>,
        @Inject(ROLE) private role$: Observable<Role>,
    ) {}

    ngOnInit(): void {
        const user$ = this.authService.getValidUser();

        this.globalRole$ = user$.pipe(map((user) => user.globalRole));

        this.currentThread$ = this.thread$.pipe(shareReplay(1));

        this.referencedThread$ = this.thread$.pipe(
            switchMap((thread) => {
                if (!!thread.referenceFrom?.threadId && !!thread.referenceFrom?.cardId) {
                    return this.threadsService.getThread(thread.referenceFrom.threadId);
                }
                return of(null);
            }),
        );

        this.currentRole$ = this.role$.pipe(shareReplay(1));
        this.threadId$ = this.thread$.pipe(map((thread) => thread.id));

        this.cardId$ = this.activatedRoute.firstChild.params.pipe(
            map((params) => params.cardId),
            distinctUntilChanged(),
            filter((cardId) => !!cardId),
        );

        this.userId$ = user$.pipe(map((user) => user.id));

        const threadExtensions = this.threadLibrary.listAll().map(({ extension }) => {
            if (!extension.showView$) {
                return of(extension);
            }

            return extension.showView$.pipe(map((isShowing) => (isShowing ? extension : null)));
        });

        this.threadExtensions$ = combineLatest(threadExtensions).pipe(
            map((extensions) => extensions.filter((extension) => extension != null)),
        );

        const hasObserverPermission$ = this.role$.pipe(
            switchMap((role) => this.permissionService.checkPermissions(role, "ObserveThread")),
        );
        this.observeThreadSubscription = combineLatest([hasObserverPermission$, this.threadId$]).subscribe(
            ([hasObserverPermission, threadId]) => {
                if (!threadId || !hasObserverPermission) {
                    return;
                }

                if (this.observingThreadId && this.observingThreadId !== threadId) {
                    this.unobserveThread(this.observingThreadId);
                    this.observingThreadId = null;
                }

                if (!this.observingThreadId) {
                    this.observingThreadId = threadId;
                    this.observeThread(threadId);
                }
            },
        );

        this.markWorkflowStepsAsSeen(this.threadId$);
        this.defineCurrentExtensionByParam();

        this.currentPath$ = this.activatedRoute.url.pipe(map((url) => url[0].path));
    }

    ngOnDestroy(): void {
        this.observeThreadSubscription?.unsubscribe();
        this.markWorkflowStepsAsSeenSubscription?.unsubscribe();

        if (this.observingThreadId) {
            this.unobserveThread(this.observingThreadId);
        }
    }

    scrollToBottom(): void {
        this.threadsContainer.nativeElement.scrollTop = 0;
    }

    addCard(event: ICreateCardEvent, thread: ITimeline): void {
        const data = { thread, ...event?.data };
        void this.dialog
            .open(event.component, {
                data,
                ...event.config,
            })
            .toPromise();
    }

    private defineCurrentExtensionByParam(): void {
        const path$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute.firstChild),
            startWith(this.activatedRoute.firstChild),
            filter((child) => !!child),
            switchMap((child) => child.url),
            map((url) => url.find((segment) => !!segment)?.path),
        );

        this.extension$ = path$.pipe(
            distinctUntilChanged(),
            switchMap((extensionId) =>
                this.threadExtensions$.pipe(
                    map((extensions) =>
                        extensions.find((extension) => extension.label.toLocaleLowerCase() === extensionId),
                    ),
                ),
            ),
        );
    }

    private observeThread(threadId: string): void {
        this.websocketService.observe(threadId);
    }

    private unobserveThread(threadId: string): void {
        this.websocketService.unobserve(threadId);
    }

    private markWorkflowStepsAsSeen(threadId$: Observable<string>): void {
        this.markWorkflowStepsAsSeenSubscription = threadId$
            .pipe(
                filter((threadId) => !!threadId),
                switchMap((threadId) => this.getWorkflowStepChannelUpdates(threadId)),
                map((threadId) => NotificationHelperService.getWorkflowStepChannel(threadId)),
            )
            .subscribe((channel) => {
                this.notificationsService.markAsRead(channel);
            });
    }

    private getWorkflowStepChannelUpdates(threadId: string): Observable<string> {
        return this.notificationsService
            .subscribeToChannel(NotificationHelperService.getWorkflowStepChannel(threadId))
            .pipe(mapTo(threadId), startWith(threadId));
    }
}
