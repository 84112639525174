import { Inject, Injectable } from "@angular/core";
import { IPluginFactory } from "projects/portal-modules/src/lib/plugins";
import { Libraries, RouteExtension } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { DocumentListComponent } from "./components/document-list/document-list.component";
import { VaultSignFocusComponent } from "./components/vault-sign-focus/vault-sign-focus.component";
import { VaultCardComponent } from "./components/vault-card/components/vault-card/vault-card.component";
import { VaultRequestCardComponent } from "./components/vault-request-card/vault-request-card.component";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { MessageAttachModalComponent } from "./components/message-attach-modal/message-attach-modal.component";
import { VaultStateAction } from "@visoryplatform/threads";
import {
    editVaultAction,
    editVaultRequestTaskAction,
    provideInfoVaultRequestTaskAction,
    signDocumentsTaskAction,
    signSingleDocumentTaskAction,
    viewVaultRequestTaskAction,
} from "./vault-task-actions";
import { PreCreateRequestComponent } from "./components/request/pre-create-request/pre-create-request.component";
import { authGuard } from "projects/portal-modules/src/lib/findex-auth";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { Observable, of } from "rxjs";
import { VaultRoutingModule } from "./vault-routing.module";
import { RequestListComponent } from "./components/request-list/request-list.component";

@Injectable()
export class VaultPlugin implements IPluginFactory {
    readonly id = "VaultPlugin";
    readonly gaEvents = GA_EVENTS;

    constructor(
        libraries: Libraries,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {
        libraries.cardViews.register("vault", VaultCardComponent);
        libraries.cardViews.register("vault-request", VaultRequestCardComponent);
        libraries.threadViews.register("vault-request", {
            label: "Requests",
            icon: null,
            showExtension: () => of(true),
            route: {
                path: "requests",
                component: RequestListComponent,
            },
        });
        libraries.threadViews.register("vault", {
            label: "Files",
            icon: null,
            showExtension: () => of(true),
            route: {
                path: "files",
                component: DocumentListComponent,
            },
        });

        libraries.taskActions.register(VaultStateAction.RequestResponse, provideInfoVaultRequestTaskAction);
        libraries.taskActions.register(VaultStateAction.EditResponse, editVaultRequestTaskAction);
        libraries.taskActions.register(VaultStateAction.ViewResponse, viewVaultRequestTaskAction);

        libraries.taskActions.register(VaultStateAction.SignFiles, signDocumentsTaskAction);
        libraries.taskActions.register(VaultStateAction.SignSingleFile, signSingleDocumentTaskAction);
        libraries.taskActions.register(VaultStateAction.EditMessage, editVaultAction);

        const createVaultCardConfig = {
            panelClass: ["threads-sidebar", "mat-dialog-no-styling"],
            autoFocus: false,
            disableClose: false,
            backdropClass: "modal-backdrop",
            closeOnNavigation: true,
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            height: "100vh",
        };

        libraries.createCard.register("message", {
            title: "Message",
            tooltipMessage: "Send a message or upload documents",
            analyticsEvent: this.gaEvents.APP_SENDMESSAGE,
            permission: null,
            icon: "la-edit",
            componentRef: MessageAttachModalComponent,
            config: createVaultCardConfig,
        });

        libraries.createCard.register("rfi", {
            title: "Request",
            tooltipMessage: "Create a task with reminders",
            analyticsEvent: this.gaEvents.APP_CREATE_REQUEST,
            permission: ["RequestCreate", "PayrunRequestCreate"],
            icon: "la-list-alt",
            componentRef: PreCreateRequestComponent,
            config: {
                panelClass: ["centered-modal"],
                width: "420px",
            },
        });

        libraries.focusWizardViews.register("vault", {
            componentRef: VaultSignFocusComponent,
            taskLabel: "Sign Document",
        });
        // TODO: change to invite to platform/dashboard link for existing users
        libraries.focusWizardViews.register("vault-signed", {
            componentRef: VaultSignFocusComponent,
            taskLabel: "Document Signed",
        });

        const extension: RouteExtension = {
            label: "Files",
            icon: "la-file-alt",
            showExtension: () => this.getShowIcon(),
            route: {
                path: "files",
                loadChildren: () => VaultRoutingModule,
                canActivate: [authGuard],
            },
        };

        libraries.appRoutes.register("files", extension);
    }
    private getShowIcon(): Observable<boolean> {
        const showVaultList = this.environment?.featureFlags?.vaultConfiguration?.showVaultList;

        return of(showVaultList);
    }
}
