<div
    class="fx-dashboard-threads"
    *ngIf="role$ | async; let role"
>
    <dashboard-greeting-banner [role]="role"></dashboard-greeting-banner>
    <div class="fx-dashboard">
        <fx-layout>
            <fx-layout-column [sizes]="{ mobile: 24, 'laptop-hd': 24, desktop: 8 }">
                <div
                    id="dashboard-threads"
                    class="fx-dashboard-card"
                >
                    <dashboard-timelines-paginated></dashboard-timelines-paginated>
                </div>
            </fx-layout-column>

            <fx-layout-column
                [sizes]="{
                    mobile: 24,
                    desktop: (role | permissions: 'UpdateInternalWorkflow' | async) ? 12 : 8,
                    laptop: 16,
                }"
            >
                <div
                    class="fx-dashboard-card"
                    id="dashboard-upcoming-meetings"
                >
                    <div class="fx-dashboard-card-title">
                        <div class="fx-dashboard-card-title-left">
                            <div class="fx-dashboard-card-title-left-icon-container">
                                <i class="las la-calendar-alt"></i>
                            </div>
                            <span>Upcoming meetings</span>
                        </div>

                        <div class="fx-dashboard-card-title-right">
                            <a
                                [routerLink]="['/calendar']"
                                [analyticsClick]="gaEvents.DASHBOARD_VIEW_ALL_UPCOMING_MEETINGS"
                                class="fx-dashboard-card-title-right-link"
                            >
                                View all
                            </a>
                        </div>
                    </div>
                    <upcoming-meetings [role]="role"></upcoming-meetings>
                </div>
            </fx-layout-column>
        </fx-layout>
    </div>
</div>
