<loader-icon
    *ngIf="loader.counter || !tableData"
    [static]="true"
    color="light"
></loader-icon>

<ng-container *ngIf="!loader.counter && tableData?.data">
    <div
        class="threads threads--with-context-menu threads--with-hover-state"
        *ngIf="tableData.data?.length; else noThreads"
    >
        <fx-table
            [tableData]="tableData"
            [trackBy]="'id' | trackProperty"
            [sortDynamically]="true"
            (sortByClick)="onSortByClick($event)"
            [sortActive]="sortActive"
            [sortDirection]="sortDirection"
            [sortEnabled]="sortEnabled()"
        >
            <!-- Service -->
            <ng-container
                *fxColumn="
                    'type';
                    sortable: true;
                    label: tableHeaders.Service;
                    mobileCollapse: true;
                    sortAnalyticsEvent: gaEvents.WORKFLOW_LISTING_FILTER_SERVICE;
                    let threadListing
                "
            >
                <div class="threads-col">
                    <span class="mobile-cell-label">{{ tableHeaders.Service }}: </span>
                    <div class="threads-title-type">
                        <a [routerLink]="['/timelines', threadListing.id]">
                            <thread-type-badge
                                [threadType]="(threadListing.type | threadTypeName | async) || '...'"
                            ></thread-type-badge>
                        </a>
                    </div>
                </div>
            </ng-container>

            <!-- Account -->
            <ng-container *ngIf="!hideAccounts">
                <ng-container
                    *fxColumn="
                        'account.label';
                        label: tableHeaders.Account;
                        sortable: true;
                        mobileCollapse: true;
                        mobileGrid: true;
                        sortAnalyticsEvent: gaEvents.WORKFLOW_LISTING_FILTER_ACCOUNT_NAME;
                        let threadListing
                    "
                >
                    <div class="threads-col">
                        <span class="mobile-cell-label">{{ tableHeaders.Account }}: </span>
                        <a [routerLink]="['/timelines', threadListing.id]">
                            <span>
                                {{ threadListing.account?.label || "" }}
                            </span>
                        </a>
                    </div>
                </ng-container>
            </ng-container>

            <!-- Workflow -->
            <ng-container
                *fxColumn="
                    'title';
                    label: tableHeaders.Workflow;
                    sortable: true;
                    mobileCollapse: true;
                    sortAnalyticsEvent: gaEvents.WORKFLOW_LISTING_FILTER_TITLE;
                    let threadListing
                "
            >
                <div class="threads-col threads-title">
                    <span class="mobile-cell-label">{{ tableHeaders.Workflow }}: </span>
                    <a
                        [routerLink]="['/timelines', threadListing.id]"
                        [analyticsClick]="gaEvents.WORKFLOW_LISTING_TIMELINE"
                        >{{ threadListing.title }}</a
                    >
                </div>
            </ng-container>

            <!-- Status -->
            <ng-container *ngIf="!hideStatusCol">
                <ng-container
                    *fxColumn="
                        'workflowStatus';
                        label: tableHeaders.Status;
                        mobileCollapse: true;
                        sortAnalyticsEvent: gaEvents.WORKFLOW_LISTING_FILTER_STATUS;
                        let threadListing
                    "
                >
                    <div class="threads-col threads-status">
                        <span class="mobile-cell-label">{{ tableHeaders.Status }}: </span>

                        <a [routerLink]="['/timelines', threadListing.id]">
                            <thread-workflow-status
                                [thread]="threadListing"
                                [workflow]="threadListing.workflow"
                                [enablePopUp]="false"
                                [enableDotStepper]="true"
                                [shouldShowTitle]="true"
                                [fullWidth]="true"
                                [role]="(threadListing | threadRole: userId) || globalRole"
                            ></thread-workflow-status>
                        </a>
                    </div>
                </ng-container>
            </ng-container>

            <!-- Due date -->
            <ng-container
                *fxColumn="
                    'dueDate';
                    label: tableHeaders.DueDate;
                    sortable: true;
                    mobileCollapse: true;
                    sortAnalyticsEvent: gaEvents.WORKFLOW_LISTING_FILTER_DUE_DATE;
                    let threadListing
                "
            >
                <div class="threads-col threads-due-date">
                    <span class="mobile-cell-label">{{ tableHeaders.DueDate }}: </span>
                    <div>
                        <a
                            *ngIf="threadListing?.dueDate"
                            [routerLink]="['/timelines', threadListing.id]"
                        >
                            <span class="threads-due-date-day"> {{ threadListing.dueDate | date: "ccc, d LLL" }}</span>
                            <span class="threads-due-date-time">{{ threadListing.dueDate | date: "h:mma" }}</span>
                        </a>
                    </div>
                </div>
            </ng-container>

            <!-- Assignees -->
            <ng-container
                *fxColumn="
                    'assignedTo';
                    label: tableHeaders.AssignedTo;
                    mobileCollapse: true;
                    sortAnalyticsEvent: gaEvents.WORKFLOW_LISTING_FILTER_ASSIGNEES;
                    let threadListing
                "
            >
                <div class="threads-col">
                    <span class="mobile-cell-label">{{ tableHeaders.AssignedTo }}: </span>

                    <a
                        [routerLink]="['/timelines', threadListing.id]"
                        class="threads-assignee"
                    >
                        <ng-container *ngIf="$any(threadListing?.avatars$ | async); let avatars">
                            <fx-avatar-multi
                                *ngIf="avatars?.length; else singleAvatar"
                                [content]="avatars"
                            ></fx-avatar-multi>
                            <ng-template #singleAvatar>
                                <fx-avatar
                                    *ngIf="avatars[0]"
                                    [name]="avatars?.name"
                                    size="small"
                                    [image]="avatars?.image"
                                ></fx-avatar>
                            </ng-template>
                        </ng-container>
                    </a>
                </div>
            </ng-container>

            <!-- Last activity -->
            <ng-container
                *fxColumn="
                    'updatedAt';
                    label: tableHeaders.LastActivity;
                    sortable: true;
                    mobileCollapse: true;
                    sortAnalyticsEvent: gaEvents.WORKFLOW_LISTING_FILTER_LAST_UPDATED;
                    let threadListing
                "
            >
                <div class="threads-col">
                    <span class="mobile-cell-label">{{ tableHeaders.LastActivity }}: </span>
                    <a [routerLink]="['/timelines', threadListing.id]">
                        <span
                            [tuiHint]="threadListing.updatedAt | date: 'dd MMM y, h:mm a'"
                            tuiHintDirection="bottom"
                        >
                            {{ threadListing.updatedAt | timeAgo }}</span
                        >
                    </a>
                </div>
            </ng-container>

            <ng-container *ngIf="showSlaRemaining$ | async">
                <ng-container
                    *fxColumn="
                        'slaRemaining';
                        label: tableHeaders.SlaRemaining;
                        sortable: true;
                        mobileCollapse: true;
                        sortAnalyticsEvent: gaEvents.WORKFLOW_LISTING_FILTER_SLA_REMAINING;
                        let threadListing
                    "
                >
                    <div class="threads-col">
                        <span class="mobile-cell-label">{{ tableHeaders.SlaRemaining }}: </span>

                        <a [routerLink]="['/timelines', threadListing.id]">
                            <sla-remaining
                                *ngIf="threadListing.workflow"
                                [showLabel]="false"
                                [step]="threadListing.workflow.steps[threadListing.workflow.currentStepId]"
                                [timezone]="threadListing.account?.metadata?.contactInfo?.timeZone"
                                [liveCountdown]="true"
                            ></sla-remaining>
                        </a>
                    </div>
                </ng-container>
            </ng-container>

            <!-- Unread -->
            <ng-container
                *fxColumn="
                    'unresolvedNotifications';
                    label: tableHeaders.Activity;
                    mobileCollapse: true;
                    sortAnalyticsEvent: gaEvents.WORKFLOW_LISTING_FILTER_UNREAD_MESSAGES;
                    let threadListing
                "
            >
                <div class="threads-col">
                    <span class="mobile-cell-label">{{ tableHeaders.Activity }}: </span>
                    <div class="threads-unread-icon">
                        <a
                            *ngIf="threadListing.unresolvedNotifications"
                            [analyticsClick]="gaEvents.WORKFLOW_LISTING_UNREAD_MESSAGES"
                            [routerLink]="['/timelines', threadListing.id]"
                            class="threads-unread-icon-badge"
                        >
                            <i class="las la-bell"></i>
                            {{ threadListing.unresolvedNotifications }}
                        </a>
                    </div>
                </div>
            </ng-container>

            <!-- Context menu -->
            <ng-container *fxColumn="'contextmenu'; let threadListing">
                <ng-container
                    *ngIf="{
                        contextMenuVisible: contextMenuVisible$ | async,
                        createThreadPermission: createThreadPermission$ | async,
                        updateInternalWorkflowPermission: updateInternalWorkflowPermission$ | async,
                        createWorkflowModalEnabled: createWorkflowModalEnabled$ | async,
                    } as context"
                >
                    <v-context-menu *ngIf="context.contextMenuVisible">
                        <a
                            *ngIf="threadListing?.workflow && context.createThreadPermission"
                            (click)="
                                createThread(
                                    threadListing?.account,
                                    globalRole,
                                    context.createWorkflowModalEnabled,
                                    threadListing
                                )
                            "
                        >
                            Create workflow
                        </a>

                        <a
                            *ngIf="threadListing.accountId && !hideAccounts"
                            [routerLink]="['/accounts', threadListing.accountId]"
                        >
                            View account
                        </a>

                        <a
                            *ngIf="
                                threadListing.workflow &&
                                threadListing.hasCancelTransition &&
                                context.updateInternalWorkflowPermission
                            "
                            (click)="cancelTimeline(threadListing, globalRole)"
                        >
                            Cancel workflow
                        </a>
                    </v-context-menu>
                </ng-container>
            </ng-container>
        </fx-table>
    </div>

    <ng-template #noThreads>
        <div class="threads-empty">
            <empty-state
                title="No results found"
                htmlText="There are no workflows matching your search.<br>Check spelling or adjust filters."
                [imageType]="emptyState.NotFound"
            >
            </empty-state>
        </div>
    </ng-template>
</ng-container>
