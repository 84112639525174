<section class="tab-section app-container">
    <div class="accounts-header">
        <h3 [ngSwitch]="brandingEnabled$ | async">
            <delphi-logo
                *ngSwitchCase="true"
                label="Memories"
            ></delphi-logo>
            <span *ngSwitchDefault>Memories</span>
        </h3>
    </div>

    <loader-icon
        *ngIf="loader.counter"
        color="light"
    ></loader-icon>

    <div
        class="memories-list"
        *ngIf="account$ | async as account"
    >
        <div class="accounts-header">
            <h4>Settings</h4>
        </div>
        <v-toggle
            [formControl]="disabled"
            (change)="save(account, { disabled: disabled?.value })"
            >Disable memories pipeline</v-toggle
        >
        <br />
        <div class="accounts-header">
            <h4>All extracted memories</h4>
        </div>

        <ng-container *ngIf="accountMemories$ | async as accountMemories">
            <memory-table
                [memories]="accountMemories"
                [canDelete]="globalRole$ | async | permissions: 'EditAccountMemories' | async"
                (removeMemory)="removeMemory(account.id, $event.key)"
            ></memory-table>
        </ng-container>
    </div>
</section>
