import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Injector,
    OnInit,
    QueryList,
    ViewChildren,
} from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { UnsavedModalDialogService } from "projects/portal-modules/src/lib/shared/services/unsaved-modal-dialog.service";
import { ThreadCardService } from "../../../../../portal-modules/src/lib/threads-ui/services/thread-card.service";
import { RequestAttachmentType, VaultRequestService } from "../../../services/vault-request.service";
import { mapTo, switchMap, take, takeUntil } from "rxjs/operators";
import { RequestActionButtonLabel, RequestTitle, VaultCardType } from "../constants/request.constants";
import { ICreateRequestModalData } from "../interfaces/request.interfaces";
import { VaultCardService } from "../../vault-card/services/vault-card.service";
import { AlertService } from "@visoryplatform/portal-ui";
import { ToastKeys, ToastSeverity } from "projects/portal-modules/src/lib/shared/constants/toast.constants";
import { ErrorMessagesConstants } from "projects/portal-modules/src/lib/shared/constants/error-messages.constants";
import { IRequestForm, IRequestItemFormGroup } from "../interfaces/IRequestForms";
import { IRequestAnalyticsTags } from "../interfaces/IRequestAnalyticsTags";
import { IRequestItem, IThreadCard } from "@visoryplatform/threads";
import { SubscriberBaseComponent } from "projects/portal-modules/src/lib/shared/components/subscriber-base.component";
import { CreateRequestService } from "./create-request.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { RfiService } from "../../../services/rfi.service";
import { DialogRef, DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";
import { AlertMessage } from "@visoryplatform/portal-ui";

interface ICreateRequestCardResponse {
    card: IThreadCard;
    attachments: { vaultId: string; fileId: string };
}

@Component({
    selector: "app-create-request",
    templateUrl: "./create-rfi-request.component.html",
    styleUrls: ["./create-rfi-request.component.scss"],
})
export class CreateRfiRequestComponent extends SubscriberBaseComponent implements OnInit, AfterViewInit {
    @ViewChildren("todoItemComponents", { read: ElementRef }) todoItemComponents: QueryList<ElementRef>;

    readonly description = "Create a request for documents, actions or questions";
    readonly buttonLabel = RequestActionButtonLabel;

    accountName: string;
    analyticsTags: IRequestAnalyticsTags;
    attachments: File[] = [];
    form: FormGroup<IRequestForm>;
    modalTitle = RequestTitle;
    loader = new Loader();

    modalData: ICreateRequestModalData;
    private dialogRef: DialogRef<string>;

    constructor(
        private cardService: ThreadCardService,
        private vaultCardService: VaultCardService,
        private unsavedDialogService: UnsavedModalDialogService,
        private alertService: AlertService,
        private createRequestService: CreateRequestService,
        private cdr: ChangeDetectorRef,
        private vaultRequestService: VaultRequestService,
        private rfiService: RfiService,
        private dialogService: DialogService,
        private injector: Injector,
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.modalData = await this.dialogService.getData<ICreateRequestModalData>(this.injector).toPromise();
        this.dialogRef = await this.dialogService.getRef<string>(this.injector).toPromise();

        this.setLocalVariables(this.modalData);
        this.setCreateRequestForm();
        this.createRequestService.initControls(this.modalData?.template, this.form);
    }

    ngAfterViewInit(): void {
        this.todoItemComponents.changes
            .pipe(mapTo(this.todoItemComponents), takeUntil(this.ngUnsubscribe))
            .subscribe((components) => {
                components.last?.nativeElement.focus();
                this.cdr.detectChanges();
            });
    }

    attachFile(attachment: File): void {
        this.attachments.push(attachment);
    }

    removeAttachment(index: number): void {
        this.attachments.splice(index, 1);
        this.alertService.hide({ channel: ToastKeys.fileUploadError });
    }

    keyboardEvent(event: KeyboardEvent): void {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            this.addRequestItem(this.form);
        }
    }

    removeControl(index: number): void {
        const control = this.form.controls.requestItems;

        if (control.controls.length > 1) {
            control.removeAt(index);
            this.form.markAsDirty();
        }
    }

    addRequestItem(form: FormGroup<IRequestForm>, isCompleted?: boolean): void {
        this.rfiService.addRequestItem(form, isCompleted);
    }

    async save(): Promise<void> {
        const { title, cardDescription, requestItems } = this.form.value;
        const cardType = this.modalData.type;
        const disableEmails = this.modalData.disableEmails;
        const invalidFileNames = await this.vaultCardService.getInvalidFilenames(this.attachments);

        if (invalidFileNames?.length) {
            this.showErrorToast(invalidFileNames);
            return;
        }

        this.createRequestCard(cardDescription, title, requestItems, cardType, disableEmails);
    }

    async close(): Promise<void> {
        if (!this.form.dirty && this.attachments.length === 0) {
            this.dialogRef.close();
        } else {
            const confirmClose = await this.unsavedDialogService.confirmClose("rfi-create");
            if (confirmClose) {
                this.dialogRef.close();
                this.alertService.hide({ channel: ToastKeys.fileUploadError });
            }
        }
    }

    private showErrorToast(invalidFileNames: string[]): void {
        const errorModalTitle = ErrorMessagesConstants.AttachmentsFailed;
        const messageText = this.vaultCardService.getFilesUploadErrorToastMessageText(invalidFileNames);
        const toastMessage: AlertMessage = {
            channel: ToastKeys.fileUploadError,
            status: ToastSeverity.Error,
            label: errorModalTitle,
            message: messageText,
        };
        this.alertService.show(toastMessage).subscribe();
    }

    private createRequestCard(
        cardDescription: string,
        vaultTitle: string,
        requestItems: Partial<IRequestItem>[],
        cardType: string,
        disableEmails?: boolean,
    ): void {
        const cardPayload = {
            cardDescription,
            vaultTitle,
            requestItems,
            disableEmails,
        };

        const createCard$ = this.cardService.createStackCard<any, ICreateRequestCardResponse>(
            this.modalData.thread.id,
            cardType,
            cardPayload,
        );

        this.loader
            .wrap(
                createCard$.pipe(
                    switchMap(async (result) => {
                        await this.vaultRequestService.uploadRequestAttachments(
                            this.modalData.thread.id,
                            result.card.id,
                            result.attachments.vaultId,
                            result.attachments.fileId,
                            this.attachments,
                            RequestAttachmentType.Rfi,
                        );
                        return result.card.id;
                    }),
                ),
            )
            .pipe(take(1))
            .subscribe((cardId) => this.dialogRef.close(cardId));
    }

    private setCreateRequestForm(): void {
        const requestItems = new FormArray<FormGroup<IRequestItemFormGroup>>([
            new FormGroup({
                requestItem: new FormControl(null),
                description: new FormControl("", [Validators.required]),
                completed: new FormControl({ value: false, disabled: true }),
            }),
        ]);

        this.form = new FormGroup<IRequestForm>({
            title: new FormControl("", [Validators.required]),
            cardDescription: new FormControl("", [Validators.required]),
            requestItems: requestItems,
        });
    }

    private setLocalVariables(modalData: ICreateRequestModalData): void {
        this.accountName = this.createRequestService.getRequestAccountName(modalData);
        this.analyticsTags = this.vaultRequestService.getAnalyticsTags(VaultCardType.VaultRequest);
    }
}
