import { Component, Inject, OnInit, computed, input } from "@angular/core";
import { WindowListenersService } from "../../services/window-listeners.service";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { TableMobileViewControlsService } from "../../services/table-mobile-view-controls.service";

@Component({
    selector: "table-filter-sort-actions",
    templateUrl: "./table-filter-sort-actions.component.html",
    styleUrls: ["./table-filter-sort-actions.component.scss"],
})
export class TableFilterSortActionsComponent implements OnInit {
    activeFilterCount = input.required<number>();

    isMobileView = false;

    filtersVisible = computed(() => this.tableMobileViewControlsService.showFilter());
    filtersActive = computed(() => this.hasVisibleOrActiveFilters());
    sortVisible = computed(() => this.tableMobileViewControlsService.showSort());
    sortApplied = computed(() => this.tableMobileViewControlsService.sortApplied());
    sortEnabled = computed(() => this.tableMobileViewControlsService.sortEnabled());
    sortActive = computed(() => this.hasSortVisibleOrApplied());
    closeVisible = computed(() => this.shouldShowCloseButton());

    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private windowListenersService: WindowListenersService,
        private tableMobileViewControlsService: TableMobileViewControlsService,
    ) {}

    ngOnInit(): void {
        this.isMobileView = this.windowListenersService.isWindowSmaller(
            this.environment.featureFlags.windowWidthTabletBreakpoint,
        );
    }

    toggleFilterVisible(): void {
        this.tableMobileViewControlsService.toggleFilter();
    }

    toggleSortVisible(): void {
        this.tableMobileViewControlsService.toggleSort();
    }

    closeActions(): void {
        this.tableMobileViewControlsService.closeActions();
    }

    resetFilters(): void {
        this.tableMobileViewControlsService.resetFilters();
    }

    private hasVisibleOrActiveFilters(): boolean {
        return this.filtersVisible() || this.activeFilterCount() > 0;
    }

    private hasSortVisibleOrApplied(): boolean {
        return this.sortVisible() || this.sortApplied();
    }

    private shouldShowCloseButton(): boolean {
        return this.isMobileView && (this.filtersVisible() || this.sortVisible());
    }
}
