<div
    class="card-item"
    [ngSwitch]="canRename || canDelete"
>
    <div
        *ngIf="!renameMode"
        class="display-name"
    >
        <div class="file-icon">
            <i
                *ngIf="!hasSignAction; else sign"
                class="las"
                [ngClass]="icon"
            ></i>
            <ng-template #sign>
                <i class="las la-signature"></i>
            </ng-template>
        </div>

        <div
            class="filename"
            (click)="downloadClicked()"
        >
            {{ displayName }}
        </div>
    </div>

    <inline-edit
        *ngIf="renameMode"
        [text]="item.displayName"
        (updated)="renameItem($event)"
        (discard)="renameMode = false"
    ></inline-edit>

    <ng-container *ngSwitchDefault>
        <div class="card-item-badge">
            <ng-container *ngIf="item.actions.includes(vaultActions.Preview) && !!item.previewItem">
                <button
                    class="fx-btn fx-btn--primary-outlined view-preview-button"
                    type="button"
                    analyticsClick="app_opendocumentpreview"
                    (click)="preview.emit()"
                >
                    View Preview
                </button>
            </ng-container>

            <ng-container *ngIf="!item.signed && hasSignAction">
                <signature-icon
                    [disabled]="disabled"
                    (click)="sign.emit()"
                    [analyticsClick]="gaEvents.APP_SIGNDOCUMENT"
                    *ngIf="canSign"
                    theme="light"
                >
                    Sign
                </signature-icon>

                <signature-icon
                    *ngIf="!canSign"
                    theme="dark"
                    [cursor]="false"
                >
                    Pending
                </signature-icon>
            </ng-container>

            <signature-icon
                *ngIf="item.signed"
                theme="dark"
                [cursor]="true"
            >
                <span>Signed by </span>
                <span>{{ item.signer | userShortName | async }} - {{ item.signedOn | date: "dd MMM YYYY" }}</span>
            </signature-icon>
        </div>
    </ng-container>

    <ng-container *ngIf="!renameMode">
        <v-context-menu
            [gutter]="false"
            *ngSwitchCase="true"
        >
            <a
                *ngIf="canRename"
                (click)="renameMode = true"
                analyticsClick="app_vault_file_rename"
            >
                Rename
            </a>

            <a
                *ngIf="canCreateReports && !isReport && !disableMarkAsReport && item.informationType === PDF_FILE_TYPE"
                [analyticsClick]="gaEvents.RFI_CREATE_MARK_AS_REPORT"
                (click)="markDocumentAsReport()"
                class="create-card-documents-menu"
            >
                <i class="las la-chart-bar"></i>
                Mark as report
            </a>

            <a
                *ngIf="!disableRequestSignature && canRequestSignature && !hasSignAction"
                (click)="markDocumentAsSign()"
                [analyticsClick]="gaEvents.APP_REQUESTSIGNATURE"
                class="create-card-documents-menu"
            >
                <i class="las la-signature"></i>
                Request signature
            </a>

            <a
                *ngIf="canDelete"
                (click)="delete.emit()"
                analyticsClick="app_vault_file_delete"
            >
                Delete
            </a>
        </v-context-menu>
    </ng-container>
</div>
