<loader-icon
    *ngIf="loader.counter"
    color="light"
></loader-icon>

<ng-container *ngIf="{ recordings: recordings$ | async, role: role$ | async, thread: thread$ | async }; let context">
    <div class="recordings">
        <div
            class="recordings-empty"
            *ngIf="context.recordings?.length === 0"
        >
            <empty-state
                title="No recordings found"
                htmlText="There have been no recordings added to this workflow"
                [imageType]="emptyStateImages.NoRecordings"
            ></empty-state>
        </div>

        <div *ngIf="tableData.data.length > 0">
            <fx-table
                [trackBy]="'id' | trackProperty"
                [tableData]="tableData"
            >
                <ng-container
                    *fxColumn="
                        'cardDescription';
                        label: tableHeaders.MeetingTitle;
                        sortable: true;
                        mobileCollapse: true;
                        mobileGrid: true;
                        let recording
                    "
                >
                    <div class="recording-col">
                        <div class="recording-col-file-container">
                            <span
                                class="recording-col-file-container-title"
                                (click)="download(recording)"
                                >{{ recording.cardDescription }}</span
                            >
                        </div>
                    </div>
                </ng-container>

                <ng-container
                    *fxColumn="
                        'duration';
                        label: tableHeaders.Duration;
                        sortable: true;
                        mobileCollapse: true;
                        mobileGrid: true;
                        let recording
                    "
                >
                    <div class="recording-col">
                        <span class="mobile-cell-label">{{ tableHeaders.Duration }}: </span>
                        <i class="las la-clock recording-duration-icon"></i>
                        <span> {{ recording.duration * 1000 | date: "mm:ss" }}</span>
                    </div>
                </ng-container>

                <ng-container
                    *fxColumn="
                        'attendees';
                        label: tableHeaders.Attendees;
                        sortable: false;
                        mobileCollapse: false;
                        mobileGrid: true;
                        let recording
                    "
                >
                    <div class="recording-col">
                        <fx-avatar-multi [content]="recording.avatars | async"> </fx-avatar-multi>
                    </div>
                </ng-container>

                <ng-container
                    *fxColumn="
                        'createdAt';
                        label: tableHeaders.DateTime;
                        sortable: true;
                        mobileCollapse: false;
                        mobileGrid: true;
                        let recording
                    "
                >
                    <div class="recording-datetime">
                        <!-- Displays recording creation date (timestamp ms) and date + duration (seconds) -->
                        <span class="mobile-cell-label">{{ tableHeaders.DateTime }}: </span>
                        <span
                            >{{ recording.createdAt | date: dateFormat.long }} -
                            {{ recording.createdAt + recording.duration * 1000 | date: dateFormat.short }}</span
                        >
                    </div>
                </ng-container>

                <ng-container *fxColumn="'contextmenu'; let recording">
                    <v-context-menu [gutter]="false">
                        <a (click)="download(recording)"> Download </a>
                    </v-context-menu>
                </ng-container>
            </fx-table>
        </div>
    </div>
</ng-container>
