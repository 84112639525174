import { Component, Input, OnInit } from "@angular/core";
import { ITimeline } from "@visoryplatform/threads";
import { LaunchDarklyFeatureFlags } from "../../../feature-flags/enums/LaunchDarklyFeatureFlags";
import { FeatureFlagService } from "../../../feature-flags/services/feature-flags.service";
import { Observable } from "rxjs";

@Component({
    selector: "workflow-details-header",
    templateUrl: "./workflow-details-header.component.html",
    styleUrls: ["./workflow-details-header.component.scss"],
})
export class WorkflowDetailsHeaderComponent implements OnInit {
    @Input() thread: ITimeline;

    hasWorkflowStatusIndicatorEnabled$: Observable<boolean>;

    constructor(private featureFlagService: FeatureFlagService) {}

    ngOnInit(): void {
        this.hasWorkflowStatusIndicatorEnabled$ = this.featureFlagService.getFlag(
            LaunchDarklyFeatureFlags.EnableWorkflowStatusIndicator,
        );
    }
}
