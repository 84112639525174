import { Injectable, Inject, signal, OnDestroy } from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";
import { WindowListenersService } from "./window-listeners.service";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../environment/environment.common";
import { ITimelineFilters } from "../../timeline/interfaces/timeline-filters";

@Injectable()
export class TableMobileViewControlsService implements OnDestroy {
    filterReset$: Observable<void>;

    showFilter = signal(true);
    showSort = signal(false);
    sortApplied = signal(false);
    isMobileView = signal(false);
    sortEnabled = signal(true);

    private filterResetSubject = new Subject<void>();
    private windowResizeSubscription: Subscription;

    constructor(
        private windowListenersService: WindowListenersService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {
        this.filterReset$ = this.filterResetSubject.asObservable();

        this.isMobileView.set(
            this.windowListenersService.isWindowSmaller(this.environment.featureFlags.windowWidthTabletBreakpoint),
        );

        this.windowResizeSubscription = this.windowListenersService.resize.subscribe(() => {
            const newIsMobileView = this.windowListenersService.isWindowSmaller(
                this.environment.featureFlags.windowWidthTabletBreakpoint,
            );
            if (this.isMobileView() !== newIsMobileView) {
                this.isMobileView.set(newIsMobileView);
                this.handleMobileViewChange();
            }
        });

        this.handleMobileViewChange();
    }

    ngOnDestroy(): void {
        this.windowResizeSubscription.unsubscribe();
    }

    toggleFilter(): void {
        const newValue = !this.showFilter();
        this.showFilter.set(newValue);
        this.showSort.set(false);
    }

    resetFilters(): void {
        this.filterResetSubject.next();
        this.sortApplied.set(false);
    }

    toggleSort(): void {
        const newValue = !this.showSort();
        this.showSort.set(newValue);
        this.showFilter.set(false);
    }

    setSortApplied(value: boolean): void {
        this.sortApplied.set(value);
    }

    closeActions(): void {
        this.showFilter.set(false);
        this.showSort.set(false);
    }

    getActiveFilterCount(defaultFilters: ITimelineFilters, selectedFilters: ITimelineFilters): number {
        return Object.keys(selectedFilters).reduce(
            (acc, key) => acc + Number(selectedFilters[key]?.key !== defaultFilters[key]?.key),
            0,
        );
    }

    setSortEnabled(value: boolean): void {
        this.sortEnabled.set(value);
    }

    private handleMobileViewChange(): void {
        if (this.isMobileView()) {
            this.showFilter.set(false);
            this.showSort.set(false);
        } else {
            this.showFilter.set(true);
            this.showSort.set(false);
        }
    }
}
