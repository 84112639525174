import { Pipe, PipeTransform } from "@angular/core";
import { Duration, DurationLikeObject } from "luxon";

const MINUTES_MS = 1000 * 60;

const DURATION_DAYS = "days";
const DURATION_HOURS = "hours";
const DURATION_MINUTES = "minutes";

const DURATION_UNITS = [
    { key: DURATION_DAYS, suffix: "d" },
    { key: DURATION_HOURS, suffix: "h" },
    { key: DURATION_MINUTES, suffix: "m" },
];

@Pipe({ name: "duration" })
export class DurationPipe implements PipeTransform {
    transform(value: number, separator = ""): string {
        if (value == null || isNaN(value)) {
            return "";
        }

        const rounded = Math.round(value / MINUTES_MS) * MINUTES_MS;

        if (rounded === 0) {
            return "0 mins";
        }

        const duration = Duration.fromMillis(Math.abs(rounded));
        const durationObj = duration.shiftTo(DURATION_DAYS, DURATION_HOURS, DURATION_MINUTES).toObject();
        const readableDuration = this.getReadableDuration(durationObj, separator);

        return `${rounded < 0 ? "-" : ""}${readableDuration}`;
    }

    private getReadableDuration(durationObj: DurationLikeObject, separator: string): string {
        return DURATION_UNITS.filter(({ key }) => durationObj[key])
            .map(({ key, suffix }) => `${durationObj[key]}${suffix}`)
            .join(`${separator} `);
    }
}
