<section class="due-dates-top-section">
    <div class="info-banner-container">
        <info-banner *ngIf="timeZone">Timezone for this account is {{ timeZone | timezoneLabel }}</info-banner>
    </div>

    <div>Calculate due dates from:</div>

    <div class="calculate-dates-toggle">
        <v-radio-block
            *ngIf="!!calculationPointOptions.length"
            [options]="calculationPointOptions"
            [formControl]="datesCalculationPoint"
            [collapsed]="true"
            [hideRadio]="true"
            size="s"
            (change)="resetDates()"
        ></v-radio-block>
    </div>

    <div class="date-from-toggle">
        <div class="date-picker">
            <div class="date-picker-input-container">
                <mat-form-field>
                    <input
                        matInput
                        [matDatepicker]="fromDatePicker"
                        [placeholder]="
                            datesCalculationPoint.value === datesCalculationPointOptions.EndDate
                                ? calculateFromPlaceholders.end
                                : calculateFromPlaceholders.start
                        "
                        (dateChange)="calculateDatesOnFromDateChanged()"
                        [formControl]="calculateFromDate"
                        class="date-picker-input"
                    />
                    <mat-datepicker-toggle
                        class="date-picker-toggle"
                        matSuffix
                        [for]="fromDatePicker"
                    >
                        <i
                            class="las la-calendar date-picker-icon"
                            matDatepickerToggleIcon
                        ></i>
                    </mat-datepicker-toggle>

                    <mat-datepicker #fromDatePicker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>

        <div
            *ngIf="calculateFromDate.value && !calculateFromDate.pristine && calculateFromDate.hasError('required')"
            class="fx-form-validation"
        >
            Date must use this format: DD/MM/YYYY
            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
        </div>
    </div>
</section>

<section class="body-section">
    <form
        *ngIf="stepConfig && calculateFromDate.value"
        autocomplete="off"
    >
        <div
            *ngIf="workflow"
            class="new-thread-rhythm-group"
        >
            <ng-container *ngFor="let step of stepsUI; trackBy: 'id' | trackProperty">
                <div
                    class="new-thread-rhythm-fields"
                    *ngIf="stepConfig.controls[step.id]"
                    [formGroup]="stepConfig.controls[step.id]"
                >
                    <label
                        [for]="step.label"
                        class="new-thread-rhythm-label"
                    >
                        {{ step.label }}
                    </label>

                    <div [ngClass]="{ 'inline-controls': !!workflowConfigurationSteps }">
                        <edit-due-date
                            *ngIf="canEditDueDates && stepConfig.controls[step.id]?.controls.dateTime"
                            [step]="step"
                            [formControl]="stepConfig.controls[step.id].controls.dateTime"
                        ></edit-due-date>

                        <ng-container *ngIf="stepConfig.controls[step.id]?.controls.assignees">
                            <ng-container [ngSwitch]="!!workflowConfigurationSteps">
                                <view-assignees
                                    *ngSwitchCase="true"
                                    [step]="step"
                                >
                                </view-assignees>

                                <edit-assignees
                                    *ngSwitchCase="false"
                                    [formControl]="stepConfig.controls[step.id].controls.assignees"
                                    [step]="step"
                                ></edit-assignees>
                            </ng-container>
                        </ng-container>
                    </div>

                    <div
                        *ngIf="stepConfig.controls[step.id].controls?.dateTime?.hasError('required')"
                        class="fx-form-validation"
                    >
                        Date must use this format: DD/MM/YYYY
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                </div>
            </ng-container>

            <div
                *ngIf="stepConfig.hasError('datesOutOfOrder')"
                class="fx-form-validation"
            >
                Dates need to be in order from earliest to latest
                <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
            </div>
        </div>
    </form>
    <div
        *ngIf="defaultThread && calculateFromDate.valid"
        class="info-message"
    >
        <em class="las la-info-circle"></em>
        <span>
            Pre-filled assignees are based on previous
            <strong>{{ defaultThread.title ?? "" }}</strong>
            workflow.
            <a
                class="reset-link"
                (click)="resetToTemplate()"
            >
                Reset
            </a>
            to clear all assignees, if required.
        </span>
    </div>
</section>
