import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import {
    ISchedule,
    IWorkflowConfiguration,
    IWorkflowVariation,
    WorkflowConfigurationUpdate,
    WorkflowCreationOptions,
} from "@visoryplatform/threads";
import { IPaginated } from "@visoryplatform/datastore-types";
import { ENVIRONMENT } from "src/app/injection-token";
import { FormControl, ValidationErrors } from "@angular/forms";
import { IWorkflowConfigurationCommon } from "../interfaces/IWorkflowFormConfigurationUpdate";
import { RolesAssigneeFormValue } from "../components/workflow-configuration-roles/workflow-configuration-roles.component";
import { AssigneeVariationsService } from "../../workflow-variations/services/assignee-variations.service";
import qs from "qs";

@Injectable({
    providedIn: "root",
})
export class WorkflowConfigurationService {
    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private http: HttpClient,
        private assigneeVariationsService: AssigneeVariationsService,
    ) {}

    createWorkflowConfiguration(
        accountId: string,
        workflowConfiguration: WorkflowConfigurationUpdate,
    ): Observable<IWorkflowConfiguration> {
        const { base } = this.environment.workflowEndpoints;
        const url = `${base}/accounts/${accountId}/workflowConfig`;

        return this.http.post<IWorkflowConfiguration>(url, { payload: workflowConfiguration });
    }

    getWorkflowConfiguration(
        accountId: string,
        configurationId: string,
        isRequired = false,
    ): Observable<IWorkflowConfiguration> {
        const { base } = this.environment.workflowEndpoints;
        const workflowConfig = isRequired ? "requiredConfig" : "workflowConfig";
        const url = `${base}/accounts/${accountId}/${workflowConfig}/${configurationId}`;

        return this.http.get<IWorkflowConfiguration>(url);
    }

    updateWorkflowConfiguration(
        accountId: string,
        configurationId: string,
        workflowConfiguration: WorkflowConfigurationUpdate,
        isRequired: boolean,
    ): Observable<IWorkflowConfiguration> {
        const { base } = this.environment.workflowEndpoints;
        const workflowConfig = isRequired ? "requiredConfig" : "workflowConfig";
        const url = `${base}/accounts/${accountId}/${workflowConfig}/${configurationId}`;

        return this.http.put<IWorkflowConfiguration>(url, { payload: workflowConfiguration });
    }

    updateWorkflowConfigurationDescription(
        accountId: string,
        configurationId: string,
        description: string,
    ): Observable<void> {
        const { base } = this.environment.workflowEndpoints;
        const url = `${base}/accounts/${accountId}/workflowConfig/${configurationId}/description`;

        return this.http.put<void>(url, { payload: { description } });
    }

    deleteWorkflowConfiguration(accountId: string, configurationId: string): Observable<IWorkflowConfiguration> {
        const { base } = this.environment.workflowEndpoints;
        const url = `${base}/accounts/${accountId}/workflowConfig/${configurationId}`;

        return this.http.delete<IWorkflowConfiguration>(url);
    }

    listConfigurations(
        accountId: string,
        next?: string,
        limit?: number,
    ): Observable<IPaginated<IWorkflowConfiguration>> {
        const { base } = this.environment.workflowEndpoints;
        const baseUrl = `${base}/accounts/${accountId}/workflowConfig`;

        const queryParams = { next, limit: limit?.toString() };
        const url = this.appendQuery(queryParams, baseUrl);

        return this.http.get<IPaginated<IWorkflowConfiguration>>(url);
    }

    listFilteredAccountConfigurations(
        accountId: string,
        enabledCreationOptions?: WorkflowCreationOptions[],
    ): Observable<IWorkflowConfiguration[]> {
        const { base } = this.environment.workflowEndpoints;

        const params = { enabledCreationOptions };
        const queryParams = qs.stringify(params, { arrayFormat: "comma", encode: false });
        const url = `${base}/accounts/${accountId}/workflowConfig/filter?${queryParams}`;

        return this.http.get<IWorkflowConfiguration[]>(url);
    }

    listRequiredConfigurations(
        accountId: string,
        next?: string,
        limit?: number,
    ): Observable<IPaginated<IWorkflowConfiguration>> {
        const { base } = this.environment.workflowEndpoints;
        const baseUrl = `${base}/accounts/${accountId}/requiredConfig`;

        const queryParams = { next, limit: limit?.toString() };
        const url = this.appendQuery(queryParams, baseUrl);

        return this.http.get<IPaginated<IWorkflowConfiguration>>(url);
    }

    scheduleConfigurationRequired(control: FormControl<IWorkflowConfigurationCommon | null>): ValidationErrors | null {
        const scheduleConfiguration = control.value?.scheduleConfiguration;
        if (!scheduleConfiguration?.scheduled) {
            return null;
        }

        return scheduleConfiguration && scheduleConfiguration?.frequency
            ? null
            : { scheduleConfigurationRequired: true };
    }

    roleAssigneesRequired(control: FormControl<RolesAssigneeFormValue | null>): ValidationErrors | null {
        if (!control.value) {
            return null;
        }

        const roleAssigneeTokens = Object.entries(control.value);
        const hasRoleAssignees = roleAssigneeTokens.some(([, assignees]) => assignees?.assignees?.length > 0);

        return hasRoleAssignees ? null : { roleAssigneesRequired: true };
    }

    getWorkflowConfigurationScheduleData(
        scheduled: boolean,
        scheduleConfiguration: Partial<ISchedule>,
    ): Partial<ISchedule> {
        if (!scheduled) {
            return {
                scheduled,
                dueDateStep: null,
                frequency: null,
            };
        }

        return {
            ...scheduleConfiguration,
            scheduled,
        };
    }

    generateWorkflowTokens(
        workflowVariations: IWorkflowVariation[],
        roleAssignees: RolesAssigneeFormValue | null,
    ): IWorkflowVariation[] {
        if (!roleAssignees) {
            return workflowVariations;
        }

        return this.assigneeVariationsService.mapRoleAssigneeToConfigTokens(workflowVariations, roleAssignees);
    }

    private appendQuery(filterParams: Record<string, string>, baseUrl: string): string {
        const params = Object.entries(filterParams)
            .filter(([, value]) => value != null)
            .map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`);

        return `${baseUrl}?${params.join("&")}`;
    }
}
