import { Pipe, PipeTransform } from "@angular/core";
import { IWorkflow, IWorkflowAction, SystemStepId } from "@visoryplatform/workflow-core";
import { Observable, from, of } from "rxjs";
import jsonata from "jsonata";

@Pipe({
    name: "showStepAction",
})
export class ShowStepActionPipe implements PipeTransform {
    transform(workflow: IWorkflow, action: IWorkflowAction): Observable<boolean> {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        if (action.id === SystemStepId.Cancelled) {
            return of(false);
        }

        if (typeof action.hidden === "boolean") {
            return of(!action.hidden);
        }

        /** Hidden changed temporarily to a jsonata expression, we need to migrate all workflows back to boolean and then we can strip out everything below */
        if (action.hidden === "true") {
            return of(false);
        }

        if (action.hidden === "false") {
            return of(true);
        }

        return from(this.evaluateHidden(workflow, action));
    }

    private async evaluateHidden(workflow: IWorkflow, action: IWorkflowAction): Promise<boolean> {
        const expression = jsonata(action.hidden as unknown as string); //HACK for reversion

        try {
            const result = await expression.evaluate(workflow);
            return typeof result === "boolean" ? !result : true;
        } catch {
            return true;
        }
    }
}
