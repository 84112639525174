<ng-container *ngIf="card$ | async; let card">
    <ng-container *ngIf="thread$ | async; let thread">
        <request
            (save)="save(thread, card)"
            (close)="save(thread, card, true)"
            [loading]="!!loader?.counter"
            [cancelButtonTitle]="null"
            [disableSave]="!form.valid"
            [saveButtonTitle]="readonly ? 'Close' : actionedPercentage === 100 ? completeButtonText : reviewButtonText"
            [analyticsPrefix]="
                actionedPercentage === 100 ? analyticsTags.analyticsEventComplete : analyticsTags.analyticsEventPending
            "
            [subtitle]="thread.title | titlecase"
            [accountName]="thread.account.label"
            [completedStatusLabel]="completedStatusLabel"
            [card]="card"
            [state]="state"
        >
            <form [formGroup]="form">
                <div class="action-request-group">
                    <request-reply
                        #requestReplyComponent
                        [replies]="replies"
                        [userId$]="userId$"
                        [thread]="thread"
                        [card]="card"
                        [focusReplyInput]="modalData.focusReplyInput"
                        [formGroup]="form"
                    ></request-reply>
                </div>
                <ng-content></ng-content>
            </form>
        </request>
    </ng-container>
</ng-container>
