<ng-container
    *ngIf="{
        card: card$ | async,
        state: state$ | async,
        thread: thread$ | async,
        form: form$ | async,
        userId: userId$ | async,
        replies: replies$ | async,
    } as context"
>
    <centered-modal-layout
        [loading]="!!loader.counter"
        [cancelButtonTitle]="context.state?.isCompleted ? buttonLabels.Close : buttonLabels.SaveClose"
        [disableSave]="context.state?.isCompleted && !modalData.allowRecomplete"
        [showSaveButton]="!context.state?.isCompleted && !context.card?.isInternal"
        [saveButtonTitle]="actionedPercentage === 100 ? buttonLabels.Complete : buttonLabels.Review"
        (save)="save(context.thread, context.card)"
        (close)="dialogRef.close()"
    >
        <div modal-header>
            <request-header
                title="Accounts payable report for review and approval"
                [isComplete]="context.state?.isCompleted"
                [completedStatusLabel]="requestStatuses.Completed"
                [accountName]="context.thread?.account?.label || ''"
            ></request-header>
        </div>

        <div modal-content>
            <div class="request-common-group">
                <request-reply
                    *ngIf="context?.thread && context?.card"
                    [replies]="context.replies"
                    [userId$]="userId$"
                    [thread]="context?.thread"
                    [card]="context?.card"
                    [focusReplyInput]="modalData?.focusReplyInput"
                    [isInternal]="false"
                ></request-reply>
            </div>

            <ng-container *ngIf="invoices$ | async as invoices; else newLoader">
                <div class="bill-approval-summary-title">
                    <h3 class="bill-approval-summary-title-heading">Bills awaiting payment</h3>
                    <div class="bill-approval-summary-title-desc">
                        Review bills for payment and approve those to be paid
                    </div>
                </div>

                <ng-container *ngIf="form$ | async as form">
                    <div [formGroup]="form">
                        <bill-approval-summary
                            [plannedDate]="context.state?.plannedDate"
                            [totalBills]="totalBills$ | async"
                            [totalApprovedBills]="totalCompletedBills$ | async"
                            [currencyCode]="currencyCode$ | async"
                        ></bill-approval-summary>
                        <bill-approval-list
                            [form]="form"
                            *ngIf="invoices?.length"
                            [invoices]="invoices"
                            [state]="context.state"
                            [allowUpdate]="!context.card.isInternal"
                            (updateInvoiceItem)="updateValue(context.thread, context.card, context.state, $event)"
                        ></bill-approval-list>
                        <div
                            class="table-footer-buttons"
                            *ngIf="!context.card.isInternal"
                        >
                            <button
                                [disabled]="context.state?.isCompleted || actionedPercentage === 100"
                                type="button"
                                (click)="approvalAll(context.thread, context.card, context.state.vaultId)"
                            >
                                Approve all
                            </button>
                        </div>
                    </div>
                </ng-container>
                <empty-state
                    *ngIf="!invoices.length"
                    title="You're all up to date!"
                    htmlText="There are no bills due for the selected range"
                >
                </empty-state>
            </ng-container>

            <ng-template #newLoader>
                <loader-icon
                    color="light"
                    [static]="true"
                ></loader-icon>
            </ng-template>
        </div>
    </centered-modal-layout>
</ng-container>
