import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ScrollingModule as ExperimentalScrollingModule } from "@angular/cdk-experimental/scrolling";
import { ListNotificationsComponent } from "./components/list-notifications/list-notifications.component";
import { NotificationComponent } from "./components/notification/notification.component";
import { NotificationChannelDirective } from "./directives/notification-channel.directive";
import { ViewportObserverService } from "./services/viewport-observer.service";
import { CountNotificationsPipe } from "./pipes/count-notifications.pipe";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { TimeAgoPipe } from "./pipes/time-ago.pipe";
import { TruncateTitleStringPipe } from "./pipes/truncate-title.pipe";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PortalUiModule } from "@visoryplatform/portal-ui";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui";
import { NotificationsPanelComponent } from "./components/notifications-panel/notifications-panel.component";

const exportDeclarations = [
    ListNotificationsComponent,
    NotificationComponent,
    NotificationChannelDirective,
    NotificationsPanelComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FxUiModule,
        ScrollingModule,
        ExperimentalScrollingModule,
        FormsModule,
        PortalUiModule,
        FindexUiModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
    ],
    declarations: [CountNotificationsPipe, TimeAgoPipe, TruncateTitleStringPipe, ...exportDeclarations],
    exports: [...exportDeclarations],
    providers: [ViewportObserverService],
})
export class NotificationsFrontendModule {}
