<header class="workflow-header">
    <div class="workflow-header-prepend">
        <threads-services-badge [threadType]="thread.type"></threads-services-badge>
    </div>

    <div class="workflow-header-content">
        <div class="workflow-header-content-leading">
            <div class="workflow-title">
                <div class="workflow-title-part">
                    {{ thread.title }}
                </div>

                <div
                    *ngIf="thread.accountId"
                    class="workflow-title-part workflow-title-account"
                >
                    <a [routerLink]="['/accounts', thread.accountId]">
                        {{ thread.account?.label }}
                    </a>
                </div>
            </div>

            <workflow-tabs [thread]="thread"></workflow-tabs>
        </div>

        <div
            class="workflow-status-indicator"
            *ngIf="hasWorkflowStatusIndicatorEnabled$ | async"
        >
            <workflow-status-indicator [workflow]="thread.workflow"></workflow-status-indicator>
        </div>
    </div>
</header>
