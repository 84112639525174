import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { DurationBadgeColour } from "../duration-badge/duration-badge.component";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "../../../feature-flags";
import { of, timer } from "rxjs";
import { filter, map, shareReplay, startWith, switchMap } from "rxjs/operators";
import { DateTime } from "luxon";
import { PermissionService } from "../../../threads-ui/services/permissions.service";
import { AuthService } from "../../../findex-auth";
import { IStep, SlaExtensionHelpers, WorkTimeExtensionHelpers } from "@visoryplatform/workflow-core";

const ONE_MINUTE_MS = 60 * 1000;

@Component({
    selector: "sla-remaining",
    templateUrl: "./sla-remaining.component.html",
    styleUrls: ["./sla-remaining.component.scss"],
})
export class SlaRemainingComponent implements OnChanges {
    @Input() step: IStep;
    @Input() liveCountdown: boolean;
    @Input() showLabel = true;
    @Input() accountId: string;
    @Input() timezone: string;

    workTime$: Observable<number | null>;
    slaTime$: Observable<number | null>;
    slaColour$: Observable<DurationBadgeColour | null>;

    private initialRemainingTime: number | null = null;

    constructor(
        private featureFlagService: FeatureFlagService,
        private permissionService: PermissionService,
        private authService: AuthService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        const { step, liveCountdown } = changes;

        if (step) {
            this.initialRemainingTime = SlaExtensionHelpers.getAdjustedSlaDate(this.step?.extensions, this.timezone);
        }

        if (step || liveCountdown) {
            this.slaTime$ = this.getSlaTimeRemaining(this.liveCountdown).pipe(shareReplay(1));
            this.workTime$ = this.getWorkTime(this.step).pipe(shareReplay(1));
            this.slaColour$ = combineLatest([this.slaTime$, this.workTime$]).pipe(
                map(([slaTime, workTime]) => this.getSlaColour(slaTime, workTime)),
            );
        }
    }

    private getSlaTimeRemaining(liveCountdown: boolean): Observable<number | null> {
        const flagEnabled$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableSlaRemainingTime);

        return flagEnabled$.pipe(
            switchMap((hasFlagEnabled) => {
                if (hasFlagEnabled) {
                    return this.getSlaRemainingInterval(liveCountdown);
                } else {
                    return of(null);
                }
            }),
        );
    }

    private getSlaRemainingInterval(liveCountdown: boolean): Observable<number | null> {
        if (!liveCountdown) {
            return of(this.initialRemainingTime);
        }

        const now = DateTime.now();
        const nextMinute = now.startOf("minute").plus({ minutes: 1 });
        const msUntilNextMinute = nextMinute.diff(now).milliseconds;

        return timer(msUntilNextMinute, ONE_MINUTE_MS).pipe(
            startWith(0),
            map((interval) => {
                if (this.initialRemainingTime === null) {
                    return null;
                }
                return this.initialRemainingTime - interval * ONE_MINUTE_MS;
            }),
        );
    }

    private getSlaColour(slaTime: number | null, workTime: number | null): DurationBadgeColour | null {
        if (!slaTime) {
            return DurationBadgeColour.Red;
        }

        if (!workTime) {
            return slaTime <= 0 ? DurationBadgeColour.Red : DurationBadgeColour.Green;
        }

        const atRiskSlaTime = workTime * 2;
        if (slaTime >= atRiskSlaTime) {
            return DurationBadgeColour.Green;
        } else if (slaTime >= 0) {
            return DurationBadgeColour.Yellow;
        } else {
            return DurationBadgeColour.Red;
        }
    }

    private getWorkTime(step: IStep): Observable<number | null> {
        const hasWorkTimeViewPermission$ = this.authService.getGlobalRole().pipe(
            filter((role) => !!role),
            switchMap((role) => this.permissionService.checkPermissions(role, "ViewWorkTime")),
        );
        const hasWorkTimeFeatureEnabled$ = this.featureFlagService.getFlag(
            LaunchDarklyFeatureFlags.EnableWorkTimeOnWorkflowStepper,
        );

        const canViewWorkTime$ = combineLatest([hasWorkTimeViewPermission$, hasWorkTimeFeatureEnabled$]).pipe(
            map(
                ([hasWorkTimeViewPermission, hasWorkTimeFeatureEnabled]) =>
                    hasWorkTimeViewPermission && hasWorkTimeFeatureEnabled,
            ),
        );

        return canViewWorkTime$.pipe(
            map((canViewWorkTime) => {
                if (canViewWorkTime) {
                    return WorkTimeExtensionHelpers.getMultipliedWorkTime(step?.extensions);
                } else {
                    return null;
                }
            }),
        );
    }
}
