import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, FormRecord } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { AcceptedCurrencies } from "@visoryplatform/payments-service-sdk";
import { AlertService } from "@visoryplatform/portal-ui";
import {
    IPayRunReport,
    IRequestItem,
    IThreadCard,
    IVaultRequestCardState,
    PayrunReportRegion,
    Role,
} from "@visoryplatform/threads";
import { GA_EVENTS_PREFIX } from "projects/portal-modules/src/lib/analytics";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ConfirmModalComponent } from "projects/portal-modules/src/lib/shared/components/confirm-modal/confirm-modal.component";
import {
    ToastMessage,
    ToastSeverity,
    ToastSummary,
} from "projects/portal-modules/src/lib/shared/constants/toast.constants";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { EMPTY, Observable, Subscription } from "rxjs";
import {
    distinctUntilChanged,
    map,
    pairwise,
    scan,
    shareReplay,
    skip,
    startWith,
    switchMap,
    take,
} from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { FormRequestItem, FormRequestTable } from "../../../vault/types/RequestTableForm";
import { IPayrunReportLineData } from "../../interfaces/IPayrunReportLineData";
import { PayrunReportDataService } from "../../services/payrun-report-data.service";

@Component({
    selector: "payrun-report-action-request",
    templateUrl: "./payrun-report-action-request.component.html",
    styleUrls: ["./payrun-report-action-request.component.scss"],
})
export class PayrunReportActionRequestComponent implements OnInit, OnDestroy {
    @Input() cardId: string;
    @Input() isCompleted: boolean;
    @Input() readonly: boolean;
    @Input() role: Role;
    @Input() state$: Observable<IVaultRequestCardState>;
    @Input() threadId: string;
    @Input() card$: Observable<IThreadCard>;
    @Output() approveAll = new EventEmitter();
    @Output() formChanges = new EventEmitter();
    @Output() requestItemsCompletedChanges = new EventEmitter<boolean[]>();
    @Output() updateControl = new EventEmitter();

    readonly appName = this.environment.appName;
    readonly regions = PayrunReportRegion;
    readonly ROLES = Role;

    currency: AcceptedCurrencies;
    form$: Observable<FormGroup<FormRequestTable>>;
    loader = new Loader();
    report$: Observable<IPayRunReport>;
    requestItemsCompletedChangesSub: Subscription;
    tableData$: Observable<MatTableDataSource<IPayrunReportLineData>>;
    refreshSubscription: Subscription;
    isInternal: boolean;

    private readonly dataSource = new MatTableDataSource<IPayrunReportLineData>();

    constructor(
        private dialog: MatDialog,
        private payrunReportDataService: PayrunReportDataService,
        private alertService: AlertService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    ngOnInit(): void {
        this.report$ = this.state$.pipe(
            switchMap((state) => {
                const fileId = state.attachments.fileId;
                const vaultId = state.vaultId;
                return this.payrunReportDataService.downloadVaultPayRunReport(vaultId, fileId);
            }),
            shareReplay(1),
        );

        this.card$.pipe(take(1)).subscribe((card) => {
            this.isInternal = card.isInternal;
        });

        this.tableData$ = this.report$.pipe(
            map((report) => {
                const dataSource = this.dataSource;
                dataSource.data = report.lines
                    .sort((a, b) => a.employeeName.localeCompare(b.employeeName))
                    .map((reportLine) => ({
                        ...reportLine,
                        currency: this.payrunReportDataService.getCurrencyByRegion(report.region),
                        hideRowChild: this.buildHideRowChild(dataSource.data, reportLine),
                    }));
                return dataSource;
            }),
        );

        const initialForm = { requestItems: new FormRecord<FormGroup<FormRequestItem>>({}) };
        const formGroup = new FormGroup<FormRequestTable>(initialForm);

        this.form$ = this.state$.pipe(
            startWith(null),
            pairwise(),
            scan((_, [oldState, currState]) => this.setRequestItems(formGroup, oldState, currState), formGroup),
        );

        this.refreshSubscription = this.state$
            .pipe(
                distinctUntilChanged((prevState, currState) => prevState.lastRefresh === currState.lastRefresh),
                skip(1),
                switchMap(() => {
                    return this.alertService.show({
                        status: ToastSeverity.Success,
                        label: ToastSummary.Success,
                        message: ToastMessage.ReportRefreshed,
                    });
                }),
            )
            .subscribe();

        this.requestItemsCompletedChangesSub = formGroup.valueChanges.subscribe((value) => {
            const requestItemsValues = Object.keys(value.requestItems).map((key) => value.requestItems[key]?.completed);
            this.requestItemsCompletedChanges.emit(requestItemsValues);
        });
    }

    ngOnDestroy(): void {
        this.refreshSubscription?.unsubscribe();
        this.requestItemsCompletedChangesSub?.unsubscribe();
    }

    refreshPayrunData(): void {
        this.dialog
            .open(ConfirmModalComponent, {
                data: {
                    confirmText: "refresh",
                    declineText: "Cancel",
                    promptText: "Are you sure you want to refresh this report?",
                    areYouSureText:
                        "Refreshing this report will update data from Employment Hero and open a task for the customer.",
                    analyticsPrefix: GA_EVENTS_PREFIX.PAYRUN_CONFIRM_REFRESH_MODAL,
                },
                panelClass: ["centered-modal"],
                width: "420px",
            })
            .afterClosed()
            .pipe(
                switchMap((value) => {
                    if (!value) {
                        return EMPTY;
                    }
                    return this.loader.wrap(this.payrunReportDataService.refreshPayRunList(this.threadId, this.cardId));
                }),
            )
            .subscribe();
    }

    approveAllRequestItems(formGroup: FormGroup<FormRequestTable>): void {
        const requestItems = formGroup.controls.requestItems;
        if (!requestItems) {
            return;
        }

        const requestItemControls = requestItems.controls;
        const keys = Object.keys(requestItemControls);
        for (const key of keys) {
            const control = requestItemControls[key];
            control.controls.completed.setValue(true);
        }
        this.approveAll.emit();
    }

    updateControlValue(formGroup: FormGroup<FormRequestTable>, control: FormGroup<FormRequestItem>): void {
        this.updateControl.emit({ control, formValues: formGroup.value });
    }

    private buildHideRowChild(data: IPayrunReportLineData[], reportLine: IPayrunReportLineData): boolean {
        return data?.find((line) => line.employeeId === reportLine.employeeId)?.hideRowChild || true;
    }

    private setRequestItems(
        formGroup: FormGroup<FormRequestTable>,
        oldState: IVaultRequestCardState,
        state: IVaultRequestCardState,
    ): FormGroup<FormRequestTable> {
        this.updateRequestItems(formGroup, oldState?.requestItems, state?.requestItems);
        return formGroup;
    }

    //Not a big fan of this but doing it as a quick win to remove lagging completing
    private updateRequestItems(
        formGroup: FormGroup<FormRequestTable>,
        oldItems: IRequestItem[],
        currItems: IRequestItem[],
    ): void {
        const removeItems =
            oldItems?.filter((oldItem) => !currItems?.some((currItem) => currItem.fileId === oldItem.fileId)) || [];
        const addItems =
            currItems?.filter((currItem) => !oldItems?.some((oldItem) => oldItem.fileId === currItem.fileId)) || [];
        const updateItems =
            currItems?.filter((currItem) => oldItems?.some((oldItem) => oldItem.fileId === currItem.fileId)) || [];

        const requestItems = formGroup.controls.requestItems;

        for (const item of removeItems) {
            const existingItemId = this.findControlByFileId(item.fileId, requestItems);
            requestItems.removeControl(existingItemId);
        }

        for (const item of addItems) {
            this.addItem(item, requestItems);
        }

        for (const item of updateItems) {
            const oldItem = oldItems.find((oItem) => oItem.fileId === item.fileId);
            this.updateItem(oldItem, item, requestItems);
        }
    }

    private addItem(item: IRequestItem, requestItems: FormRecord<FormGroup<FormRequestItem>>): void {
        const newControl = new FormGroup<FormRequestItem>({
            fileId: new FormControl({ value: item.fileId, disabled: false }),
            description: new FormControl({ value: item.description, disabled: false }),
            completed: new FormControl({ value: item.response?.complete?.state, disabled: false }),
        });

        requestItems.addControl(item.description, newControl);
    }

    private updateItem(
        oldItem: IRequestItem,
        item: IRequestItem,
        requestItems: FormRecord<FormGroup<FormRequestItem>>,
    ): void {
        const existingItemId = this.findControlByFileId(item.fileId, requestItems);
        const requestItemControl = requestItems.controls[existingItemId];

        if (this.hasItemStateChanged(oldItem, item)) {
            requestItemControl.patchValue({
                completed: item.response?.complete?.state,
            });
        } else {
            requestItemControl.patchValue({
                description: item.description,
            });
        }
    }

    private findControlByFileId(fileId: string, requestItemControls: FormRecord<FormGroup<FormRequestItem>>): string {
        const keys = Object.keys(requestItemControls.controls);
        return keys.find((key) => requestItemControls.controls[key].value?.fileId === fileId);
    }

    private hasItemStateChanged(oldItem: Partial<IRequestItem>, currItem: Partial<IRequestItem>): boolean {
        return oldItem?.response?.complete?.state !== currItem?.response?.complete?.state;
    }
}
