<div
    class="new-thread-type"
    [formGroup]="form"
>
    <div
        *ngIf="selectedConfigurationOption === WorkflowConfigurationOptions.Configured"
        class="form-group fx-form-group"
    >
        <label class="fx-form-label">{{ selectedConfigurationOption }} Workflows:</label>
        <div
            class="fx-form-control"
            [ngSwitch]="!workflowConfigurations?.length"
        >
            <warning-banner *ngSwitchCase="true"
                >There are no workflows configured for the selected service</warning-banner
            >

            <div
                *ngSwitchDefault
                class="v-select-container"
            >
                <v-select
                    placeholder="Select a workflow"
                    [options]="workflowConfigurations"
                    [optionContent]="optionContent"
                    [tuiTextfieldCleaner]="false"
                    formControlName="workflowConfigurationId"
                    valueKey="id"
                >
                </v-select>

                <ng-template
                    #optionContent
                    let-option
                >
                    <ng-container *ngIf="option | findIn: workflowConfigurations : 'id'; let config">
                        <ng-container *ngIf="config.description; else justLabel">
                            {{ config.label }}: {{ config.description }}
                        </ng-container>

                        <ng-template #justLabel> {{ config.label }} </ng-template>
                    </ng-container>
                </ng-template>
            </div>

            <div
                *ngIf="
                    !form.controls.workflowConfigurationId.valid &&
                    form.controls.workflowConfigurationId.touched &&
                    workflowDesigns?.length
                "
                class="fx-form-validation"
            >
                Workflow is required.
                <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
            </div>
        </div>
    </div>

    <div
        *ngIf="
            selectedConfigurationOption === WorkflowConfigurationOptions.Blank ||
            selectedConfigurationOption === WorkflowConfigurationOptions.WorkflowConfig
        "
    >
        <label class="fx-form-label"
            >{{
                selectedConfigurationOption === WorkflowConfigurationOptions.Blank ? selectedConfigurationOption : ""
            }}
            Workflow:</label
        >
        <div class="v-select-container">
            <v-select
                [placeholder]="workflowDesigns?.length ? selectPlaceholder : noWorkflowsPlaceholder"
                [options]="workflowDesigns"
                [optionContent]="designsOptionContent"
                [tuiTextfieldCleaner]="false"
                formControlName="designId"
                valueKey="id"
            >
            </v-select>

            <ng-template
                #designsOptionContent
                let-option
            >
                {{ (option | findIn: workflowDesigns : "id")?.label }}
            </ng-template>
        </div>

        <div
            *ngIf="!form.controls.designId.valid && form.controls.designId.touched && workflowDesigns?.length"
            class="fx-form-validation"
        >
            Workflow is required.
            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
        </div>
    </div>
</div>
