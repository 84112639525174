import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import {
    ITimeline,
    InternalRoles,
    Role,
    ThreadFilterSources,
    ThreadFilters,
    ThreadStatus,
} from "@visoryplatform/threads";
import { Observable, merge, Subscription } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { AuthService } from "../../../findex-auth";
import { Loader } from "../../../shared/services/loader";
import { PortalService } from "../../../shared/services/portal.service";
import { FilterOption, ITimelineFilters } from "../../interfaces/timeline-filters";
import { Paginator } from "../../../shared/services/paginator";
import { IPaginated } from "@visoryplatform/datastore-types";
import { TableThreadListing } from "../../../threads-ui/services/threads-enrichment.service";
import { SearchableThreadsService } from "projects/portal-modules/src/lib/threads-ui/services/searchable-threads.service";
import { ALL_OPTION } from "../../constants/option-constants";
import { SystemStepId } from "@visoryplatform/workflow-core";
import { IPaginatorSort } from "../../../shared/interfaces/IPaginatorSort";
import { ThreadFilterService } from "../../../threads-ui/services/thread-filter.service";
import { TableMobileViewControlsService } from "../../../shared/services/table-mobile-view-controls.service";
import { NgChanges } from "../../../shared/interfaces/ng-changes.interface";

@Component({
    selector: "timelines-paginated",
    templateUrl: "./timelines-paginated.component.html",
    styleUrls: ["./timelines-paginated.component.scss"],
    providers: [{ provide: Loader, useClass: Loader }],
})
export class TimelinesPaginatedComponent implements OnInit, OnChanges, OnDestroy {
    @Input() filters: ITimelineFilters;
    @Input() hideAccounts: boolean;
    @Input() includeAll: boolean;

    readonly pageSize = 10;

    role$: Observable<Role>;
    userId$: Observable<string>;
    threads$: Observable<ITimeline[]>;
    paginator = new Paginator<ITimeline>(this.pageSize);
    threadSub: Subscription;

    constructor(
        private authService: AuthService,
        private portalService: PortalService,
        private searchableThreadsService: SearchableThreadsService,
        private threadFilterService: ThreadFilterService,
        private tableMobileViewControlsService: TableMobileViewControlsService,
        public loader: Loader,
    ) {}

    ngOnInit(): void {
        const user$ = this.authService.getValidUser();

        this.userId$ = user$.pipe(
            map((user) => user.id),
            shareReplay(1),
        );

        this.role$ = user$.pipe(
            map((user) => user.globalRole),
            shareReplay(1),
        );

        this.threads$ = this.paginator.wrap();

        this.threadSub = this.threads$.pipe(map((threads) => threads.length > 0)).subscribe((results) => {
            this.tableMobileViewControlsService.setSortEnabled(results);
        });
    }

    ngOnChanges(changes: NgChanges<{ filters: ITimelineFilters; includeAll: boolean }>): void {
        const { filters, includeAll } = changes;

        if ((includeAll || filters) && this.filters) {
            this.paginator.refresh((page, _, sort) => this.getThreadListing(page, sort, this.filters, this.includeAll));
        }
    }

    ngOnDestroy(): void {
        this.threadSub?.unsubscribe();
    }

    private createThreadList$(
        page: string,
        sort: IPaginatorSort,
        searchParams: ThreadFilters,
        searchFilter: string,
        includeAll: boolean,
        delphiSort: boolean,
    ): Observable<IPaginated<ITimeline>> {
        const searchThreadList$ = this.portalService.getSearchThreadList(
            page,
            this.pageSize,
            searchParams,
            searchFilter,
            sort.sort,
            sort.order,
            includeAll,
            delphiSort,
        );
        return this.loader.wrap(searchThreadList$);
    }

    private getThreadListing(
        page: string,
        sort: IPaginatorSort,
        filters: ITimelineFilters,
        includeAll: boolean,
    ): Observable<IPaginated<TableThreadListing>> {
        const searchFilter = this.filters.search;
        const searchParams$ = this.role$.pipe(
            map((role) => this.getSearchParams(filters, role)),
            shareReplay(1),
        );
        const userId$ = this.userId$.pipe(shareReplay(1));

        const threadList$ = searchParams$.pipe(
            switchMap((searchParams) =>
                this.createThreadList$(
                    page,
                    sort,
                    searchParams,
                    searchFilter,
                    includeAll ?? filters.includeAll,
                    filters.delphiSort,
                ),
            ),
        );

        const threadListUpdates$ = searchParams$.pipe(
            switchMap((searchParams) =>
                this.getThreadListUpdates(
                    page,
                    userId$,
                    sort,
                    searchParams,
                    searchFilter,
                    includeAll,
                    filters.delphiSort,
                ),
            ),
        );

        return merge(threadList$, threadListUpdates$).pipe(
            switchMap((listing) => this.searchableThreadsService.getListingCreatedUpdates(this.userId$, listing)),
            switchMap((listing) => this.searchableThreadsService.getThreadUpdates(listing)),
            map((listing) => this.searchableThreadsService.getEnrichedListings(listing)),
            shareReplay(1),
        );
    }

    private getThreadListUpdates(
        page: string,
        userId$: Observable<string>,
        sort: IPaginatorSort,
        searchParams: ThreadFilters,
        searchFilter: string,
        includeAll: boolean,
        delphiSort: boolean,
    ): Observable<IPaginated<ITimeline>> {
        const searchThreadList$ = this.portalService.getSearchThreadList(
            page,
            this.pageSize,
            searchParams,
            searchFilter,
            sort.sort,
            sort.order,
            includeAll ?? this.filters.includeAll,
            delphiSort,
        );

        return userId$.pipe(
            switchMap((userId) => this.searchableThreadsService.threadListUpdates(page, userId)),
            switchMap(() => searchThreadList$),
        );
    }

    private getSearchParams(filters: ITimelineFilters, role: Role): ThreadFilters {
        const { account: accountFilter, workflow: workflowFilter, type: typeFilter } = filters;
        const account = accountFilter.key === ALL_OPTION.key ? undefined : accountFilter.key;
        const workflow = workflowFilter.key === ALL_OPTION.key ? undefined : workflowFilter.key;
        const type = typeFilter.key === ALL_OPTION.key ? undefined : typeFilter.key;

        const assigneeSearchParam = this.getAssigneeSearchParam(filters.assignees, role);
        const workflowStatusSearchParam = this.getWorkflowStatusSearchParam(filters.status, role);
        return {
            account,
            workflow,
            type,
            ...assigneeSearchParam,
            ...workflowStatusSearchParam,
        };
    }

    private getAssigneeSearchParam(assigneeFilterOption: FilterOption, role: Role): ThreadFilters {
        const source: ThreadFilterSources = this.threadFilterService.getAssigneeFilterSource(role);
        const assignees = assigneeFilterOption.key === ALL_OPTION.key ? undefined : assigneeFilterOption.key;
        return { [source]: assignees };
    }

    private getWorkflowStatusSearchParam(workflowStatus: FilterOption, role: Role): ThreadFilters {
        const source: ThreadFilterSources = InternalRoles.includes(role) ? "internalStepStatus" : "externalStepStatus";
        const status = workflowStatus.key === ALL_OPTION.key ? undefined : workflowStatus.key;
        const sourceStatus = status === ThreadStatus.closed ? SystemStepId.End : status;
        return { [source]: sourceStatus };
    }
}
