<ng-container *ngIf="tableData?.data">
    <div
        id="workflow-configurations"
        class="workflow-configurations"
        *ngIf="tableData.data?.length > 0; else noConfigurations"
    >
        <fx-table
            [tableData]="tableData"
            [trackBy]="'id' | trackProperty"
            [rowClickable]="true"
            [sortEnabled]="false"
        >
            <a
                class="workflow-configurations-col"
                *fxColumn="'type'; label: tableHeaders.Service; mobileCollapse: true; let workflow"
                [routerLink]="['/accounts', workflow.accountId, 'workflow-configurations', workflow.id]"
                [queryParams]="{ requiredConfigurationId: workflow.requiredConfigurationId }"
                [analyticsClick]="gaEvents.WORKFLOW_LIST_VIEW_CONFIGURATION"
            >
                <span class="workflow-configurations-title">
                    <thread-type-badge
                        [threadType]="(workflow.type | threadTypeName | async | titlecase) || '...'"
                    ></thread-type-badge>
                </span>
            </a>

            <ng-container *ngIf="showAccountLabel">
                <a
                    class="workflow-configurations-col"
                    *fxColumn="'accountLabel'; label: tableHeaders.AccountLabel; mobileCollapse: true; let workflow"
                    [routerLink]="['/accounts', workflow.accountId, 'workflow-configurations', workflow.id]"
                    [queryParams]="{ requiredConfigurationId: workflow.requiredConfigurationId }"
                >
                    <span class="mobile-cell-label">{{ tableHeaders.AccountLabel }}: </span>
                    <span class="workflow-primary-name">{{ workflow.account?.label }}</span>
                </a>
            </ng-container>

            <a
                class="workflow-configurations-col"
                *fxColumn="'workflowType'; label: tableHeaders.WorkflowType; mobileCollapse: true; let workflow"
                [routerLink]="['/accounts', workflow.accountId, 'workflow-configurations', workflow.id]"
                [queryParams]="{ requiredConfigurationId: workflow.requiredConfigurationId }"
            >
                <span class="mobile-cell-label">{{ tableHeaders.WorkflowType }}: </span>
                <span class="workflow-primary-name">{{ workflow.label }}</span>
            </a>

            <a
                class="workflow-configurations-col"
                *fxColumn="'workflowTokens'; label: tableHeaders.Variation; mobileCollapse: true; let workflow"
                [routerLink]="['/accounts', workflow.accountId, 'workflow-configurations', workflow.id]"
                [analyticsClick]="gaEvents.WORKFLOW_LIST_VIEW_CONFIGURATION"
            >
                <span class="mobile-cell-label">{{ tableHeaders.Variation }}: </span>
                <span
                    *ngIf="workflow?.workflowTokens"
                    class="workflow-primary-name"
                >
                    <ng-container
                        *ngFor="
                            let variation of workflow?.workflowTokens | workflowVariationConfigTokenLabels;
                            trackBy: 'id' | trackProperty;
                            let last = last
                        "
                    >
                        {{ variation | appendComma: last }}
                    </ng-container>
                </span>
            </a>

            <a
                class="workflow-configurations-col"
                *fxColumn="'description'; label: tableHeaders.Description; mobileCollapse: true; let workflow"
                [routerLink]="['/accounts', workflow.accountId, 'workflow-configurations', workflow.id]"
                [queryParams]="{ requiredConfigurationId: workflow.requiredConfigurationId }"
                [analyticsClick]="gaEvents.WORKFLOW_LIST_VIEW_CONFIGURATION"
            >
                <span class="mobile-cell-label">{{ tableHeaders.Description }}: </span>
                <span class="workflow-title-label">{{ workflow.description }}</span>
            </a>

            <ng-container *ngIf="!isRequiredConfiguration">
                <a
                    class="workflow-configurations-col"
                    *fxColumn="'frequency'; label: tableHeaders.Frequency; mobileCollapse: true; let workflow"
                    [routerLink]="['/accounts', workflow.accountId, 'workflow-configurations', workflow.id]"
                    [queryParams]="{ requiredConfigurationId: workflow.requiredConfigurationId }"
                    [analyticsClick]="gaEvents.WORKFLOW_LIST_VIEW_CONFIGURATION"
                >
                    <span class="mobile-cell-label">{{ tableHeaders.Frequency }}: </span>
                    <span
                        *ngIf="workflow?.scheduleData?.scheduled"
                        class="workflow-primary-name workflow-configurations-col-frequency"
                    >
                        {{ workflow?.scheduleData?.frequency | rruleFrequencyDescription }}
                    </span>
                </a>
            </ng-container>

            <ng-container *ngIf="!isRequiredConfiguration">
                <a
                    class="workflow-configurations-col"
                    *fxColumn="'scheduling'; label: tableHeaders.NextSchedule; mobileCollapse: true; let workflow"
                    [routerLink]="['/accounts', workflow.accountId, 'workflow-configurations', workflow.id]"
                    [queryParams]="{ requiredConfigurationId: workflow.requiredConfigurationId }"
                    [analyticsClick]="gaEvents.WORKFLOW_LIST_VIEW_CONFIGURATION"
                >
                    <span class="mobile-cell-label">{{ tableHeaders.NextSchedule }}: </span>
                    <span
                        *ngIf="workflow?.scheduleData?.scheduled"
                        class="workflow-primary-name"
                    >
                        {{ workflow | nextInstance | async | dateTime: "EEE, d MMM yyyy h:mm a" }}
                    </span>
                </a>
            </ng-container>
        </fx-table>
    </div>

    <ng-template #noConfigurations>
        <div class="workflows-empty">
            <span>No configurations found.</span>
        </div>
    </ng-template>
</ng-container>
