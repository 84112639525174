import { Component, OnDestroy, OnInit } from "@angular/core";
import { combineLatest, Observable, of, Subscription } from "rxjs";
import { distinctUntilChanged, filter, map, shareReplay, switchMap, take } from "rxjs/operators";
import { Location } from "@angular/common";
import { PreferenceMethodFrequency, RecipientSettings } from "@visoryplatform/notifications-core";
import { Frequencies, PreferenceLabels } from "../../../constants/notification-settings-contants";
import { UserNotificationSettingsService } from "../../../services/user-notification-settings.service";
import { Loader } from "../../../../shared/services/loader";
import { AnalyticsService, GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { ActivatedRoute } from "@angular/router";
import { Role } from "@visoryplatform/threads";

@Component({
    selector: "notification-settings-component",
    templateUrl: "./notification-settings.component.html",
    styleUrls: ["./notification-settings.component.scss"],
})
export class NotificationSettingsComponent implements OnInit, OnDestroy {
    currentUserRole$: Observable<Role>;
    frequencies = PreferenceMethodFrequency;
    isCurrentUserOwnSettings$: Observable<boolean>;
    loader = new Loader();
    preferenceLabels: PreferenceLabels;
    userSettings: RecipientSettings;
    frequency: PreferenceMethodFrequency;
    readUserSettingsSub: Subscription;
    frequencyOptions = this.getFrequencyOptions();

    private settingsOwnerUserId$: Observable<string>;

    constructor(
        private userNotificationSettingsService: UserNotificationSettingsService,
        private location: Location,
        private analytics: AnalyticsService,
        private route: ActivatedRoute,
        private auth: AuthService,
    ) {}

    ngOnInit(): void {
        this.preferenceLabels = Object.keys(PreferenceMethodFrequency).reduce<PreferenceLabels>(
            (prev, key: Frequencies) => ({ ...prev, [key]: PreferenceMethodFrequency[key] }),
            {},
        );
        this.setUserVariables();
    }

    ngOnDestroy(): void {
        this.readUserSettingsSub?.unsubscribe();
    }

    originalOrder(): number {
        return 0;
    }

    back(): void {
        this.location.back();
    }

    getUserSettings(userId: string): Observable<RecipientSettings> {
        const userSettings$ = this.userNotificationSettingsService.getSettingsByUser(userId).pipe(shareReplay(1));
        return this.loader.wrap(userSettings$);
    }

    changePreferenceMethodFrequency($event: { value?: PreferenceMethodFrequency }): void {
        this.analytics.recordEvent("notification-settings", `GA_EVENTS.NOTIFICATION_SETTINGS_SELECT_${$event.value}`);
    }

    saveChanges(): void {
        this.analytics.recordEvent("notification-settings", GA_EVENTS.NOTIFICATION_SETTINGS_SAVE);

        const saveUserSettings$ = combineLatest([this.settingsOwnerUserId$]).pipe(
            switchMap(([userId]) => {
                const userSettings = this.getMergedUserSettings();
                return this.loader.wrap(this.userNotificationSettingsService.saveSettingsByUser(userId, userSettings));
            }),
        );

        saveUserSettings$.pipe(take(1)).subscribe((userSettings) => {
            this.userSettings = userSettings;
        });
    }

    private getMergedUserSettings(): RecipientSettings {
        const digest = this.userSettings.preference.digest;
        const preference = this.userSettings.preference;
        return {
            ...this.userSettings,
            preference: {
                ...preference,
                digest: { ...digest, frequency: this.frequency },
            },
        };
    }

    private getIsCurrentUserOwnSettings(settingsOwnerUserId: string): Observable<boolean> {
        return this.auth.getUserId().pipe(map((currentUserId) => currentUserId === settingsOwnerUserId));
    }

    private setUserVariables(): void {
        const userIdFromRoute$ = this.route.parent.params.pipe(
            map((params) => params.userId),
            distinctUntilChanged(),
        );

        this.currentUserRole$ = this.auth.getUser().pipe(
            filter((user) => !!user),
            map((user) => user.globalRole),
        );

        this.settingsOwnerUserId$ = userIdFromRoute$.pipe(
            switchMap((userIdFromRoute) => {
                return userIdFromRoute ? of(userIdFromRoute) : this.auth.getUserId();
            }),
        );

        this.readUserSettingsSub = this.settingsOwnerUserId$
            .pipe(
                switchMap((userId) => {
                    return this.getUserSettings(userId);
                }),
            )
            .subscribe((userSettings) => {
                this.userSettings = userSettings;
                this.frequency = userSettings.preference.digest.frequency;
            });

        this.isCurrentUserOwnSettings$ = this.settingsOwnerUserId$.pipe(
            switchMap((settingsOwnerUserId) => this.getIsCurrentUserOwnSettings(settingsOwnerUserId)),
        );
    }

    private getFrequencyOptions(): Array<{ label: string; value: PreferenceMethodFrequency }> {
        return Object.entries(PreferenceMethodFrequency).map(([key, value]) => ({
            label: key,
            value: value,
        }));
    }
}
