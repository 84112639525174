import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "truncateTitleString",
    pure: true,
})
export class TruncateTitleStringPipe implements PipeTransform {
    private readonly SEPARATOR = " | ";
    private readonly ELLIPSIS = "...";
    private readonly MAX_LENGTH = 60;

    transform(threadName: string | boolean, accountName: string | boolean): string {
        if (typeof threadName !== "string" || typeof accountName !== "string") {
            return "";
        }

        // If combined length fits, return full string
        const fullString = `${threadName}${this.SEPARATOR}${accountName}`;
        if (fullString.length <= this.MAX_LENGTH) {
            return fullString;
        }

        const availableSpace = this.MAX_LENGTH - this.SEPARATOR.length;
        const minLength = Math.floor(availableSpace / 3);

        // Keep thread name, truncate account name
        if (this.canKeepFullThread(threadName, minLength, availableSpace)) {
            return this.truncateAccountName(threadName, accountName, availableSpace);
        }

        // Truncate thread name, keep account name
        if (this.canKeepFullAccount(accountName, minLength, availableSpace)) {
            return this.truncateThreadName(threadName, accountName, availableSpace);
        }

        // Truncate both proportionally
        return this.truncateBoth(threadName, accountName, availableSpace, minLength);
    }

    private canKeepFullThread(threadName: string, minLength: number, availableSpace: number): boolean {
        return threadName.length + minLength + this.ELLIPSIS.length <= availableSpace;
    }

    private canKeepFullAccount(accountName: string, minLength: number, availableSpace: number): boolean {
        return accountName.length + minLength + this.ELLIPSIS.length <= availableSpace;
    }

    private truncateAccountName(threadName: string, accountName: string, availableSpace: number): string {
        const accountSpace = availableSpace - threadName.length;
        const truncatedAccount = accountName.substring(0, accountSpace - this.ELLIPSIS.length);
        return `${threadName}${this.SEPARATOR}${truncatedAccount}${this.ELLIPSIS}`;
    }

    private truncateThreadName(threadName: string, accountName: string, availableSpace: number): string {
        const threadSpace = availableSpace - accountName.length;
        const truncatedThread = threadName.substring(0, threadSpace - this.ELLIPSIS.length);
        return `${truncatedThread}${this.ELLIPSIS}${this.SEPARATOR}${accountName}`;
    }

    private truncateBoth(threadName: string, accountName: string, availableSpace: number, minLength: number): string {
        const totalLength = threadName.length + accountName.length;
        const threadRatio = threadName.length / totalLength;

        const availableForText = availableSpace - this.ELLIPSIS.length * 2;
        let threadLength = Math.max(minLength, Math.floor(availableForText * threadRatio));
        let accountLength = Math.max(minLength, availableForText - threadLength);

        if (threadLength + accountLength > availableForText) {
            const excess = threadLength + accountLength - availableForText;
            threadLength = Math.max(minLength, threadLength - Math.ceil(excess / 2));
            accountLength = Math.max(minLength, accountLength - Math.floor(excess / 2));
        }

        const truncatedThread = threadName.substring(0, threadLength);
        const truncatedAccount = accountName.substring(0, accountLength);

        return `${truncatedThread}${this.ELLIPSIS}${this.SEPARATOR}${truncatedAccount}${this.ELLIPSIS}`;
    }
}
