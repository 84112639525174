import { Injectable, signal, WritableSignal } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class AnnounceService {
    unread = signal(0);

    constructor() {}

    getUnread(): WritableSignal<number> {
        return this.unread;
    }

    setUnread(unread: number): void {
        this.unread.set(unread);
    }
}
