<sidebar-modal-layout
    header="{{ previewWorkflowTitle }}"
    (close)="close()"
>
    <loader-icon
        *ngIf="loader.counter"
        color="light"
        [showBackground]="true"
    ></loader-icon>
    <stepper-layout
        [linear]="true"
        [formGroup]="form"
        endStepLabel="Create"
        (completed)="createWorkflow()"
    >
        <stepper-step
            label="Select service"
            [completed]="!form.hasError(errorKeys.formInvalid)"
        >
            <ng-container
                *ngIf="!loader.counter && referenceCard && {}"
                class="forward-message-preview"
            >
                <forward-message-preview
                    [name]="
                        referenceReply
                            ? (referenceReply.actor | userToName | async)
                            : (referenceCard.createdBy | userToName | async)
                    "
                    [image]="
                        referenceReply
                            ? (referenceReply.actor | profilePicture | async)
                            : (referenceCard.createdBy | profilePicture | async)
                    "
                    [timestamp]="referenceReply ? referenceReply.timestamp : referenceCard.createdAt"
                    [message]="referenceReply ? referenceReply.message : referenceCard.description"
                ></forward-message-preview>
            </ng-container>

            <select-workflow-service
                formControlName="service"
                [workflowDesignTypes]="currentDesignTypes$ | async"
                [showWorkflowOptionSelectors]="true"
            >
            </select-workflow-service>

            <ng-container
                *ngIf="{
                    workflowDesigns: currentDesigns$ | async,
                    workflowConfigurations: currentConfigurations$ | async,
                    selectedDesignType: selectedDesignType$ | async,
                    selectedWorkflowDesign: selectedWorkflowDesign$ | async,
                    selectedWorkflowConfiguration: selectedWorkflowConfiguration$ | async,
                } as context"
            >
                <select-workflow-form
                    *ngIf="form.value.service?.threadType && form.value.service?.configurationOption"
                    formControlName="workflow"
                    [workflowDesigns]="context.workflowDesigns | orderBy: ['label', sortOption.ASC]"
                    [workflowConfigurations]="context.workflowConfigurations | orderBy: ['label', sortOption.ASC]"
                    [selectedConfigurationOption]="form.value.service?.configurationOption"
                ></select-workflow-form>

                <select-workflow-token
                    formControlName="workflowTokens"
                    [selectedDesignType]="context.selectedDesignType"
                    [selectedWorkflowDesign]="context.selectedWorkflowDesign"
                    [selectedWorkflowConfiguration]="context.selectedWorkflowConfiguration"
                ></select-workflow-token>

                <edit-workflow-title
                    *ngIf="context.selectedWorkflowDesign || context.selectedWorkflowConfiguration"
                    formControlName="title"
                    [selectedDesignType]="context.selectedDesignType"
                >
                </edit-workflow-title>
            </ng-container>
        </stepper-step>
        <stepper-step
            label="Configure service"
            [completed]="!form.hasError(errorKeys.participantsRequired)"
        >
            <ng-container
                *ngIf="{
                    selectedWorkflowDesign: selectedWorkflowDesign$ | async,
                    selectedWorkflowConfiguration: selectedWorkflowConfiguration$ | async,
                } as context"
            >
                <configure-workflow
                    [workflowDesign]="context.selectedWorkflowDesign"
                    [timezone]="account?.metadata?.contactInfo?.timeZone"
                    [workflowConfigurationSteps]="context.selectedWorkflowConfiguration?.steps"
                    [role]="role"
                    (assignees)="setAssignees($event, context.selectedWorkflowConfiguration)"
                    (dueDates)="dueDates = $event"
                    (isWorkflowValid)="isWorkflowValid = $event"
                    (isFormValid)="isParticipantsValid = $event"
                ></configure-workflow>
            </ng-container>
        </stepper-step>

        <ng-container
            *ngIf="{
                participants: form.value.participants,
            } as context"
        >
            <stepper-step
                label="Edit participants"
                [completed]="!form.hasError(errorKeys.participantsRequired)"
            >
                <edit-participants
                    [participants]="context.participants"
                    (participantsChanged)="updateParticipants($event)"
                ></edit-participants>
            </stepper-step>
        </ng-container>
    </stepper-layout>
</sidebar-modal-layout>
