import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
    Account,
    CardReference,
    CardReply,
    IThreadCard,
    IWorkflowConfiguration,
    IWorkflowDesignType,
    Role,
    SortOption,
    IThread,
    TitleTemplateService,
    WorkflowExtensionService,
    WorkflowTokenResolver,
    WorkflowCreationOptions,
} from "@visoryplatform/threads";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { WorkflowService } from "../../../../services/workflow/workflow.service";
import { combineLatest, forkJoin, Observable, of, Subscription } from "rxjs";
import { IWorkflowDesign, IWorkflowInputs } from "@visoryplatform/workflow-core";
import { FormControl, FormGroup, ValidationErrors } from "@angular/forms";
import { WorkflowConfigurationOptions } from "../../types/UniqueThreadType";
import { WorkflowConfigurationService } from "projects/portal-modules/src/lib/account/services/workflow-configuration.service";
import { ServiceControl, WorkflowControl } from "../select-workflow-form/select-workflow-form.component";
import { SelectWorkflowTokenControl } from "../../types/SelectDesignType";
import { filter, map, shareReplay, switchMap, take } from "rxjs/operators";
import { ParticipantService } from "../../../../services/participant.service";
import { IParticipantUi } from "../../../../interfaces/IParticipantUi";
import { ThreadsService } from "../../../../services/threads.service";
import { CreateWorkflowModalService } from "../../../../services/create-workflow-modal.service";
import { ToastSummary } from "projects/portal-modules/src/lib/shared/constants/toast.constants";
import { ToastSeverity } from "projects/portal-modules/src/lib/shared/constants/toast.constants";
import { AlertService } from "@visoryplatform/portal-ui";
import { DateTime } from "luxon";
import { WorkflowVariationsService } from "projects/portal-modules/src/lib/workflow-variations/services/workflow-variations.service";

export interface CreateWorkflowModalData {
    account: Account;
    defaultThread: IThread;
    role?: Role;
    referenceFrom: CardReference;
    referenceCard: IThreadCard;
    referenceReply: CardReply;
}

type CreateWorkflow = {
    service: ServiceControl;
    workflow: WorkflowControl;
    workflowTokens: SelectWorkflowTokenControl;
    title: string;
    participants: IParticipantUi[];
};

type CreateWorkflowForm = FormGroup<{
    service: FormControl<ServiceControl>;
    workflow: FormControl<WorkflowControl>;
    workflowTokens: FormControl<SelectWorkflowTokenControl>;
    title: FormControl<string>;
    participants: FormControl<IParticipantUi[]>;
}>;

@Component({
    selector: "create-workflow-modal",
    templateUrl: "./create-workflow-modal.component.html",
    styleUrls: ["./create-workflow-modal.component.scss"],
    providers: [{ provide: Loader, useValue: new Loader() }],
})
export class CreateWorkflowModalComponent implements OnInit, OnDestroy {
    readonly sortOption = SortOption;
    readonly errorKeys = {
        formInvalid: "formInvalid",
        participantsRequired: "participantsRequired",
    };

    isWorkflowValid = false;
    isParticipantsValid = false;

    form: FormGroup;
    account: Account;
    clonedWorkflow: IThread;
    workflowConfigurationId: string;
    referenceCard: IThreadCard;
    referenceReply: CardReply;
    referenceFrom: CardReference;
    role?: Role;
    assignees?: Record<string, string[]>;
    dueDates?: Record<string, DateTime>;
    previewWorkflowTitle: string;
    designTypeRequiredVariations: number | null;

    selectedDesignType$: Observable<IWorkflowDesignType>;
    selectedWorkflowDesign$: Observable<IWorkflowDesign>;
    selectedWorkflowConfiguration$: Observable<IWorkflowConfiguration>;
    currentDesignTypes$: Observable<IWorkflowDesignType[]>;
    currentDesigns$: Observable<IWorkflowDesign[]>;
    currentConfigurations$: Observable<IWorkflowConfiguration[]>;

    workflowDataSub: Subscription;
    serviceControlSub: Subscription;
    workflowControlSub: Subscription;
    workflowVariationControlSub: Subscription;
    titleControlSub: Subscription;

    private workflowDesignTypes$: Observable<IWorkflowDesignType[]>;
    private workflowDesigns$: Observable<IWorkflowDesign[]>;
    private workflowConfigurations$: Observable<IWorkflowConfiguration[]>;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: CreateWorkflowModalData,
        public loader: Loader,
        private dialogRef: MatDialogRef<CreateWorkflowModalComponent>,
        private threadsService: ThreadsService,
        private workflowService: WorkflowService,
        private workflowConfigurationService: WorkflowConfigurationService,
        private participantService: ParticipantService,
        private alertService: AlertService,
        private createWorkflowModalService: CreateWorkflowModalService,
        private workflowVariationsService: WorkflowVariationsService,
    ) {
        this.initModalData(this.data);
        this.previewWorkflowTitle = this.createWorkflowModalService.getDefaultPreviewTitle(this.account?.label);
        this.form = this.buildForm();
    }

    ngOnInit(): void {
        this.workflowDataSub = this.initWorkflowData();

        const workflowConfigurationId = this.data.defaultThread?.workflowConfigurationId;
        if (workflowConfigurationId) {
            this.cloneFromWorkflowConfig(workflowConfigurationId);
        }

        this.serviceControlSub = this.handleServiceChanges();
        this.workflowControlSub = this.handleWorkflowChanges();
        this.workflowVariationControlSub = this.handleWorkflowVariationsChanges();
        this.titleControlSub = this.handleTitleChanges();
    }

    ngOnDestroy(): void {
        this.workflowDataSub?.unsubscribe();
        this.serviceControlSub?.unsubscribe();
        this.workflowControlSub?.unsubscribe();
        this.workflowVariationControlSub?.unsubscribe();
        this.titleControlSub?.unsubscribe();
    }

    createWorkflow(): void {
        combineLatest([this.selectedDesignType$, this.selectedWorkflowDesign$, this.selectedWorkflowConfiguration$])
            .pipe(
                take(1),
                switchMap(([workflowDesignType, selectedWorkflowDesign, selectedWorkflowConfiguration]) => {
                    const { title, workflowTokens, participants } = this.form.value;

                    const workflowVariations = this.createWorkflowModalService.getWorkflowDesignTokens(
                        selectedWorkflowConfiguration,
                        workflowTokens,
                    );
                    const workflowInputs = this.getWorkflowInputs(
                        selectedWorkflowDesign,
                        selectedWorkflowConfiguration,
                        this.dueDates,
                        this.assignees,
                    );

                    const createdWorkflow$ = this.threadsService.createThread(
                        title,
                        selectedWorkflowDesign.id,
                        this.account.id,
                        participants,
                        workflowDesignType.threadType,
                        workflowInputs,
                        selectedWorkflowConfiguration?.id,
                        workflowVariations,
                        this.referenceFrom,
                        workflowDesignType?.restrictCardsToInternal,
                    );

                    return this.loader.wrap(createdWorkflow$);
                }),
            )
            .subscribe((workflow) => {
                this.showAlert(workflow);
                this.dialogRef.close(workflow);
            });
    }

    showAlert(thread: IThread): void {
        this.alertService
            .show({
                status: ToastSeverity.Success,
                label: ToastSummary.NewWorkflowCreated,
                message: `${thread.title} has been created. Click to view`,
                routerLink: ["workflows", thread.id],
            })
            .subscribe();
    }

    close(): void {
        this.dialogRef.close();
    }

    updateParticipants(participants: IParticipantUi[]): void {
        this.form.patchValue({
            participants,
        });
    }

    setAssignees(assignees: Record<string, string[]>, selectedWorkflowConfiguration?: IWorkflowConfiguration): void {
        this.assignees = assignees;

        const participants$ = this.getParticipantsFromAssignees(selectedWorkflowConfiguration, assignees);
        participants$.pipe(take(1)).subscribe((participants) => {
            this.updateParticipants(participants);
        });
    }

    private initModalData(modalData: CreateWorkflowModalData): void {
        this.account = modalData.account;
        this.workflowConfigurationId = modalData.defaultThread?.workflowConfigurationId;
        this.clonedWorkflow = modalData.defaultThread;
        this.role = modalData.role;
        this.referenceCard = modalData.referenceCard;
        this.referenceReply = modalData.referenceReply;
        this.referenceFrom = modalData.referenceFrom;

        /**
         * NOTE: This issue occurs because all devs share a workflow table
         * This should never happen outside of development
         */
        if (!this.account) {
            throw new Error("Account not found. Please confirm provided account exists.");
        }
    }

    private buildForm(): CreateWorkflowForm {
        const serviceControlDefault: ServiceControl = {
            threadType: null,
            configurationOption: WorkflowConfigurationOptions.Configured,
        };

        const workflowControlDefault: WorkflowControl = {
            designId: null,
            workflowConfigurationId: this.workflowConfigurationId,
        };

        return new FormGroup(
            {
                service: new FormControl<ServiceControl>(serviceControlDefault),
                workflow: new FormControl<WorkflowControl | null>(workflowControlDefault),
                workflowTokens: new FormControl<SelectWorkflowTokenControl | null>(null),
                title: new FormControl<string | null>(null),
                participants: new FormControl<IParticipantUi[]>([]),
            },
            { validators: this.validateForm.bind(this) },
        );
    }

    private validateForm(formGroup: CreateWorkflowForm): ValidationErrors | null {
        if (!formGroup) {
            return { formInvalid: true };
        }

        const errors: ValidationErrors = {};
        const { service, workflow, title, workflowTokens, participants } = formGroup.value;

        const hasService = !!service.threadType && !!service.configurationOption;
        const hasWorkflow = !!workflow.designId || !!workflow.workflowConfigurationId;
        const hasTitle = !!title && formGroup.controls.title.valid;
        const hasWorkflowTokens = this.designTypeRequiredVariations
            ? this.designTypeRequiredVariations <= workflowTokens?.workflowTokenIds?.length
            : true;
        const hasParticipants = !!participants?.length && this.isParticipantsValid;

        const formIsCompleted = hasService && hasWorkflow && hasTitle && hasWorkflowTokens;

        if (formIsCompleted && !hasParticipants) {
            errors.participantsRequired = true;
        }

        if (!formIsCompleted) {
            errors.formInvalid = true;
        }

        const formHasErrors = Object.keys(errors).length;
        return formHasErrors ? errors : null;
    }

    private initWorkflowData(): Subscription {
        this.workflowDesignTypes$ = this.workflowService.listDesignTypes().pipe(shareReplay(1));
        this.workflowDesigns$ = this.workflowService
            .listAccountWorkflowDesigns(this.account.id, [
                WorkflowCreationOptions.AdhocFromBlank,
                WorkflowCreationOptions.AdhocFromConfig,
            ])
            .pipe(
                map((paginated) => paginated.result),
                shareReplay(1),
            );
        this.workflowConfigurations$ = this.workflowConfigurationService
            .listFilteredAccountConfigurations(this.account.id, [WorkflowCreationOptions.AdhocFromConfig])
            .pipe(shareReplay(1));

        this.setDesignsFromConfig(this.form.value);

        return this.loader
            .wrap(forkJoin([this.workflowDesignTypes$, this.workflowDesigns$, this.workflowConfigurations$]))
            .subscribe();
    }

    private handleServiceChanges(): Subscription {
        return this.form.controls.service.valueChanges.subscribe((val: ServiceControl) => {
            this.handleServiceValueChanges(val);
        });
    }

    private handleWorkflowChanges(): Subscription {
        return this.form.controls.workflow.valueChanges.subscribe((val: WorkflowControl) => {
            this.handleWorkflowValueChanges(val);
        });
    }

    private handleWorkflowVariationsChanges(): Subscription {
        return this.form.controls.workflowTokens.valueChanges.subscribe((val: SelectWorkflowTokenControl) => {
            this.handleWorkflowVariationsValueChanges(val);
        });
    }

    private handleTitleChanges(): Subscription {
        return this.form.controls.title.valueChanges.subscribe((val: string | null) => {
            this.handleTitleValueChanges(val);
        });
    }

    private handleServiceValueChanges(formVal: ServiceControl): void {
        this.resetFormValues();

        if (formVal.configurationOption === WorkflowConfigurationOptions.Blank) {
            this.setDesignsFromBlank(formVal);
        } else if (formVal.configurationOption === WorkflowConfigurationOptions.Configured) {
            this.setDesignsFromConfig(formVal);
        }
    }

    private handleWorkflowValueChanges(formVal: WorkflowControl): void {
        this.resetWorkflowData();

        if (formVal.designId) {
            this.workflowDesignSelected(formVal);
        }

        if (formVal.workflowConfigurationId) {
            this.workflowConfigSelected(formVal);
        }
    }

    private workflowDesignSelected(workflowFormValue: WorkflowControl): void {
        this.selectedWorkflowDesign$ = this.workflowDesigns$.pipe(
            map((designs) => designs.find((design) => design.id === workflowFormValue.designId)),
        );

        this.selectedDesignType$ = this.workflowDesignTypes$.pipe(
            map((designTypes) =>
                designTypes.find((designType) => designType.workflowDesignId === workflowFormValue.designId),
            ),
        );

        this.handleDesignTypeChanges();

        combineLatest([this.selectedDesignType$, this.selectedWorkflowDesign$])
            .pipe(take(1))
            .subscribe(([designType, design]) => {
                if (!designType?.titleTemplate && design) {
                    this.form.controls.title.setValue(design.label);
                }
            });
    }

    private workflowConfigSelected(workflowFormValue: WorkflowControl): void {
        this.selectedWorkflowConfiguration$ = this.workflowConfigurations$.pipe(
            map((configs) => configs.find((config) => config.id === workflowFormValue.workflowConfigurationId)),
        );

        this.selectedWorkflowConfiguration$
            .pipe(
                take(1),
                filter((config) => !!config?.workflowTokens?.length),
            )
            .subscribe((config) => {
                const workflowTokenIds = config.workflowTokens.map((token) => token.id);
                this.form.patchValue({
                    workflowTokens: {
                        workflowTokenIds,
                    },
                });
            });

        const selectedWorkflowDesign$ = combineLatest([
            this.workflowDesigns$,
            this.selectedWorkflowConfiguration$,
        ]).pipe(
            filter(([designs, config]) => !!designs.length && !!config),
            map(([designs, config]) => designs.find((design) => design.id === config.designId)),
        );

        this.selectedWorkflowDesign$ = this.createWorkflowModalService.getResolvedWorkflowDesign(
            selectedWorkflowDesign$,
            this.selectedWorkflowConfiguration$,
        );

        this.selectedDesignType$ = combineLatest([this.workflowDesignTypes$, this.selectedWorkflowDesign$]).pipe(
            filter(([designTypes, design]) => !!designTypes.length && !!design),
            map(([designTypes, design]) => designTypes.find((designType) => designType.workflowDesignId === design.id)),
        );

        this.handleDesignTypeChanges();
    }

    private handleWorkflowVariationsValueChanges(workflowVariationsFormValue: SelectWorkflowTokenControl): void {
        if (!workflowVariationsFormValue) {
            return;
        }

        this.selectedWorkflowDesign$ = combineLatest([
            this.selectedWorkflowConfiguration$,
            this.selectedWorkflowDesign$,
        ]).pipe(
            take(1),
            filter(([, design]) => !!design),
            map(([config, design]) => ({
                variations: this.createWorkflowModalService.getWorkflowDesignTokens(
                    config,
                    workflowVariationsFormValue,
                ),
                design,
            })),
            map(({ variations, design }) => {
                const { resolvedDesign } = WorkflowTokenResolver.resolveWorkflowTokens(variations, design);
                return resolvedDesign;
            }),
        );

        this.selectedDesignType$ = combineLatest([this.workflowDesignTypes$, this.selectedWorkflowDesign$]).pipe(
            map(([designTypes, design]) => designTypes.find((designType) => designType.workflowDesignId === design.id)),
        );
    }

    private handleTitleValueChanges(formTitleVal: string | null): void {
        if (!formTitleVal) {
            this.previewWorkflowTitle = this.createWorkflowModalService.getDefaultPreviewTitle(this.account.label);
            return;
        }

        combineLatest([this.selectedWorkflowDesign$, this.selectedDesignType$])
            .pipe(
                take(1),
                filter(([design, designType]) => !!design && !!designType),
                map(([design, designType]) =>
                    this.getTitleFromTemplate(design, this.account, formTitleVal, designType?.titleTemplate),
                ),
            )
            .subscribe((titleTemplate) => {
                this.previewWorkflowTitle = `${this.account.label} - ${titleTemplate}`;
            });
    }

    private getTitleFromTemplate(
        design: IWorkflowDesign,
        account: Account,
        customThreadTitle: string,
        titleTemplate?: string,
    ): string {
        const title = TitleTemplateService.getThreadTitle(design, account, customThreadTitle, titleTemplate);
        return title?.trim();
    }

    private resetFormValues(): void {
        this.resetWorkflowData();

        const dependentFormValues: Partial<CreateWorkflow> = {
            workflow: {
                designId: null,
                workflowConfigurationId: null,
            },
            workflowTokens: null,
            title: null,
            participants: [],
        };

        this.form.patchValue(dependentFormValues);
    }

    private resetWorkflowData(): void {
        this.designTypeRequiredVariations = null;
        this.selectedDesignType$ = of(null);
        this.selectedWorkflowDesign$ = of(null);
        this.selectedWorkflowConfiguration$ = of(null);

        const dependentFormValues: Partial<CreateWorkflow> = {
            workflowTokens: null,
            title: null,
            participants: [],
        };

        this.form.patchValue(dependentFormValues);
    }

    private setDesignsFromBlank(formVal: ServiceControl): void {
        this.currentDesignTypes$ = this.createWorkflowModalService.getDesignTypes(
            this.workflowDesignTypes$,
            this.workflowDesigns$,
            this.filterByDesign,
        );
        this.currentDesigns$ = this.createWorkflowModalService.getFilteredDesigns(
            formVal,
            this.workflowDesignTypes$,
            this.workflowDesigns$,
            this.filterByDesign,
        );
        this.currentConfigurations$ = of([]);
    }

    private setDesignsFromConfig(formVal: ServiceControl): void {
        this.currentDesignTypes$ = this.createWorkflowModalService.getDesignTypes(
            this.workflowDesignTypes$,
            this.workflowConfigurations$,
            this.filterByConfig,
        );
        this.currentDesigns$ = this.createWorkflowModalService.getFilteredDesigns(
            formVal,
            this.workflowDesignTypes$,
            this.workflowDesigns$,
            this.filterByDesign,
        );
        this.currentConfigurations$ = this.createWorkflowModalService.getFilteredConfigurations(
            formVal,
            this.workflowDesignTypes$,
            this.workflowConfigurations$,
            this.filterByConfig,
        );
    }

    private getParticipantsFromAssignees(
        selectedWorkflowConfiguration: IWorkflowConfiguration,
        assignees: Record<string, string[]>,
    ): Observable<IParticipantUi[]> {
        if (selectedWorkflowConfiguration) {
            const clonedParticipants = this.clonedWorkflow?.participants || [];
            return this.createWorkflowModalService.getParticipantsFromWorkflowConfig(
                selectedWorkflowConfiguration,
                clonedParticipants,
            );
        } else {
            const uniqueAssignees = new Set(Object.values(assignees || []).flat());
            return this.participantService.getClonedParticipants(this.form.value.participants, [...uniqueAssignees]);
        }
    }

    private getWorkflowInputs(
        workflowDesign: IWorkflowDesign,
        workflowConfig: IWorkflowConfiguration,
        dueDates: Record<string, DateTime>,
        assignees: Record<string, string[]>,
    ): IWorkflowInputs {
        const configTokenInputs = WorkflowExtensionService.getConfigTokenInputs(workflowDesign);
        const assigneesInputs = this.getAssignees(workflowConfig, assignees);

        return WorkflowExtensionService.getExtensionInputUpdates(
            workflowDesign,
            assigneesInputs,
            dueDates,
            configTokenInputs,
        );
    }

    private getAssignees(
        workflowConfig: IWorkflowConfiguration,
        assignees: Record<string, string[]>,
    ): Record<string, string[]> {
        if (!workflowConfig) {
            return assignees;
        }

        return WorkflowExtensionService.getStepAssigneesFromConfig(workflowConfig);
    }

    private handleDesignTypeChanges(): void {
        this.selectedDesignType$?.pipe(take(1)).subscribe((designType) => {
            this.designTypeRequiredVariations = this.workflowVariationsService.getRequiredVariations(
                designType?.workflowToken,
            );
            this.form.updateValueAndValidity();
        });
    }

    private cloneFromWorkflowConfig(workflowConfigurationId: string): void {
        const workflowControlData = { designId: null, workflowConfigurationId };

        this.workflowConfigSelected(workflowControlData);

        this.selectedDesignType$.pipe(take(1)).subscribe((designType) => {
            this.form.patchValue({
                service: {
                    threadType: designType?.threadType,
                    configurationOption: WorkflowConfigurationOptions.Configured,
                },
                workflow: workflowControlData,
            });
        });
    }

    private filterByDesign = (design: IWorkflowDesign, designType: IWorkflowDesignType): boolean =>
        design.id === designType.workflowDesignId;

    private filterByConfig = (config: IWorkflowConfiguration, designType: IWorkflowDesignType): boolean =>
        config.designId === designType.workflowDesignId;
}
