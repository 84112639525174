import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IThread, IThreadCard, Account, ITimeline, ThreadFilters, SortOption } from "@visoryplatform/threads";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environmentCommon, EnvironmentSpecificConfig } from "../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { IPaginated } from "@visoryplatform/datastore-types";
import qs from "qs";

export type IInitialVaultPayload = {
    description: string;
    documents: {
        description: string;
        category: string;
    }[];
};

type OnboardingResponse = {
    thread: IThread;
    card?: IThreadCard;
    appointmentId: string;
};

@Injectable({ providedIn: "root" })
export class PortalService {
    private readonly pageSize = 10;

    constructor(
        private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    createOnboarding(
        participantId: string,
        createMeeting: boolean,
        initialVaultPayload?: IInitialVaultPayload,
    ): Observable<OnboardingResponse> {
        const { base } = this.environment.sigmaEndpoints;
        const { onboarding } = environmentCommon.sigmaEndpoints;
        const url = `${base}${onboarding}`;

        const appName = this.environment.appName;

        return this.http.post<OnboardingResponse>(url, {
            participantId,
            createMeeting,
            appName,
            threadType: this.environment.featureFlags.initialThreadDetails.threadType,
            initialVaultPayload,
        });
    }

    getSearchThreadList(
        next?: string,
        limit = this.pageSize,
        searchParams?: ThreadFilters,
        searchFilter?: string,
        sortBy?: string,
        orderOption?: string,
        includeAll?: boolean,
        delphiSort?: boolean,
    ): Observable<IPaginated<ITimeline>> {
        const { base } = this.environment.commonEndpoints;
        const { searchTimelines } = environmentCommon.threadsEndpoints;

        const [sort, order] = this.getSortAndOrder(sortBy, orderOption, delphiSort);

        const filterQuery = qs.stringify(searchParams, { arrayFormat: "comma", encode: false });
        const params = {
            filterQuery,
            next,
            sortBy: sort || "",
            order: order || "",
            limit: limit?.toString(),
            searchFilter: searchFilter ? encodeURIComponent(searchFilter) : "",
            includeAll,
        };
        const url = `${base}${searchTimelines}`;

        return this.http.get<IPaginated<ITimeline>>(url, { params });
    }

    getDashboardSearchThreadList(
        next?: string,
        limit = this.pageSize,
        searchParams?: ThreadFilters,
    ): Observable<IPaginated<ITimeline>> {
        const { base } = this.environment.commonEndpoints;
        const { dashboardSearchTimelines } = environmentCommon.threadsEndpoints;

        const params = { ...searchParams, next, limit: limit?.toString() };
        const queryParams = qs.stringify(params, { arrayFormat: "comma", encode: false });
        const url = `${base}${dashboardSearchTimelines}?${queryParams}`;

        return this.http.get<IPaginated<ITimeline>>(url);
    }

    getThreadListById(threadId: string): Observable<ITimeline> {
        const { base } = this.environment.commonEndpoints;
        const { timelines } = environmentCommon.threadsEndpoints;
        const url = `${base}${timelines}/${threadId}`;

        return this.http.get<IPaginated<ITimeline>>(url).pipe(map((list) => list?.result?.pop()));
    }

    async updateContactAccountMetadata(contactId: string, accountMetadata: any): Promise<void> {
        const { contacts } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.sigmaEndpoints;
        const url = `${base}${contacts}/${contactId}/account`;
        await this.http.post<Account>(url, { accountMetadata }).toPromise();
    }

    private getSortAndOrder(sortBy: string, order: string, delphiSort: boolean): [string, string] {
        if (delphiSort) {
            return ["dueDateSlaWeight", SortOption.ASC];
        }
        return [sortBy, order];
    }
}
