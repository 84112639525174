<div
    class="step-entry-context-menu-container"
    *ngIf="FEATURE_FLAGS.DisableAdhocCreateCardBar | featureEnabled | async"
>
    <v-context-menu
        [gutter]="false"
        [overlayPosition]="contextMenuPosition"
        [offsetY]="5"
        [offsetX]="175"
        (open)="openContextMenu($event)"
    >
        <div custom-button>
            <button
                class="step-entry-context-menu-button"
                tuiHint="More actions"
                tuiHintDirection="right"
            >
                <div class="dots">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </button>
        </div>
        <ng-container *ngFor="let cardExtension of cardExtensions">
            <a
                *ngIf="
                    !cardExtension.extension.hideFromMenu &&
                    (cardExtension.extension.featureFlags | featureEnabled | async) &&
                    (cardExtension.extension.permission
                        ? (role | permissions: cardExtension.extension.permission | async)
                        : true)
                "
                [analyticsClick]="cardExtension.extension.analyticsEvent"
                [analyticsLabel]="
                    role === roles.Client && cardExtension.extension.title === 'Meeting'
                        ? 'Meeting booked by Client'
                        : ''
                "
                [tuiHint]="cardExtension.extension.tooltipMessage"
                tuiHintDirection="top"
                class="step-entry-context-menu-item"
                (click)="
                    createCard(
                        cardExtension.extension.componentRef,
                        cardExtension.extension?.data,
                        cardExtension.extension?.config
                    )
                "
            >
                <i
                    [ngClass]="cardExtension.extension.icon"
                    class="las step-entry-context-menu-icon"
                ></i>
                {{ cardExtension.extension.title }}
            </a>
        </ng-container>
    </v-context-menu>
</div>
