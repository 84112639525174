import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { IPayRunReport, IVaultRequestCardState, Role } from "@visoryplatform/threads";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { EMPTY, Observable } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { PayrunReportDataService } from "../../services/payrun-report-data.service";
import { IPayrunReportLineData } from "../../interfaces/IPayrunReportLineData";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmModalComponent } from "projects/portal-modules/src/lib/shared/components/confirm-modal/confirm-modal.component";
import { GA_EVENTS, GA_EVENTS_PREFIX } from "projects/portal-modules/src/lib/analytics";

@Component({
    selector: "payrun-report-edit-request",
    templateUrl: "./payrun-report-edit-request.component.html",
    styleUrls: ["./payrun-report-edit-request.component.scss"],
})
export class PayrunReportEditRequestComponent implements OnInit {
    @Input() state$: Observable<IVaultRequestCardState>;
    @Output() updateControl = new EventEmitter();
    @Input() role: Role;
    @Input() threadId: string;
    @Input() cardId: string;

    report$: Observable<IPayRunReport>;
    tableData$: Observable<MatTableDataSource<IPayrunReportLineData>>;
    loader = new Loader();

    readonly gaEvents = GA_EVENTS;

    private readonly dataSource = new MatTableDataSource<IPayrunReportLineData>();

    constructor(
        private dialog: MatDialog,
        private payrunReportDataService: PayrunReportDataService,
    ) {}

    ngOnInit() {
        this.report$ = this.state$.pipe(
            switchMap((state) => {
                const fileId = state.attachments.fileId;
                const vaultId = state.vaultId;

                return this.payrunReportDataService.downloadVaultPayRunReport(vaultId, fileId);
            }),
            shareReplay(1),
        );

        this.tableData$ = this.report$.pipe(
            map((report: IPayRunReport) => {
                const dataSource = this.dataSource;
                dataSource.data = report.lines
                    .sort((a, b) => a.employeeName.localeCompare(b.employeeName))
                    .map((reportLine) => ({
                        ...reportLine,
                        currency: this.payrunReportDataService.getCurrencyByRegion(report.region),
                        hideRowChild: true,
                    }));
                return dataSource;
            }),
        );
    }

    refreshPayrunData(): void {
        this.dialog
            .open(ConfirmModalComponent, {
                data: {
                    confirmText: "refresh",
                    declineText: "Cancel",
                    promptText: "Are you sure you want to refresh this report?",
                    areYouSureText:
                        "Refreshing this report will update data from Employment Hero and open a task for the customer.",
                    analyticsPrefix: GA_EVENTS_PREFIX.PAYRUN_CONFIRM_REFRESH_MODAL,
                },
                panelClass: ["centered-modal"],
                width: "420px",
            })
            .afterClosed()
            .pipe(
                switchMap((value) => {
                    if (!value) {
                        return EMPTY;
                    }
                    return this.loader.wrap(this.payrunReportDataService.refreshPayRunList(this.threadId, this.cardId));
                }),
            )
            .subscribe();
    }
}
