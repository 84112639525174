<ng-container *ngIf="thread && card">
    <v-context-menu
        *ngIf="!hideMenu || (canForwardOrCreateWorkflow$ | async)"
        [gutter]="false"
    >
        <ng-content select="[other-actions]"></ng-content>
        <a
            *ngIf="canForwardCard$ | async"
            (click)="forward(thread, card, reply)"
            >Forward</a
        >
        <a
            *ngIf="
                (canCreateWorkflow$ | async) && {
                    featureEnabled: featureEnabled$ | async,
                } as context
            "
            (click)="convertToWorkflow(thread.id, card, reply, context.featureEnabled)"
            >Convert to workflow</a
        >
        <ng-content select="[delete-action]"></ng-content>
        <ng-content></ng-content>
    </v-context-menu>
</ng-container>
