<div
    [formGroup]="mobileSortFilters"
    class="mobile-sort"
>
    @if (sortEnabled) {
        <div>
            @if (!hasMobileViewControlsService) {
                <div class="mobile-sort-toggle">
                    @if (sortApplied) {
                        <div class="mobile-sort-toggle-count">1</div>
                    }
                    <button
                        (click)="toggleSortVisible()"
                        [ngClass]="{
                            'mobile-sort-toggle-active': mobileSortActive(),
                            'mobile-sort-toggle-inactive': !mobileSortActive(),
                        }"
                    >
                        <i class="las la-signal"></i> Sort
                    </button>
                </div>
            }
            <div
                [ngClass]="{ 'mobile-sort-hidden': !mobileSortVisible() }"
                class="mobile-sort-filters"
            >
                <v-select
                    id="selectedSortBy"
                    placeholder="Sort by"
                    [options]="SORT_BY_OPTIONS"
                    [optionContent]="sortByContent"
                    [valueContent]="sortByContent"
                    formControlName="selectedSortBy"
                    [tuiTextfieldCleaner]="false"
                    (selectionChanged)="sortBy($event.value, sort?.direction)"
                >
                </v-select>
                <ng-template
                    #sortByContent
                    let-option
                >
                    {{ option.key }}
                </ng-template>

                <v-select
                    id="selectedSortDirection"
                    placeholder="Sort direction"
                    [options]="SORT_DIRECTIONS_OPTIONS"
                    [optionContent]="sortDirectionsContent"
                    [valueContent]="sortDirectionsContent"
                    formControlName="selectedSortDirection"
                    [tuiTextfieldCleaner]="false"
                    (selectionChanged)="sortBy(sort?.active, $event.value)"
                >
                </v-select>
                <ng-template
                    #sortDirectionsContent
                    let-option
                >
                    {{ option.key }}
                </ng-template>

                <div class="mobile-sort-actions">
                    @if (hasMobileViewControlsService) {
                        <button
                            class="mobile-sort-actions-clear"
                            (click)="resetSort()"
                        >
                            <i class="las la-sync-alt la-lg"></i>
                            <span>Clear all</span>
                        </button>
                    }

                    <button
                        class="fx-btn fx-btn--primary fx-btn--medium mobile-sort-actions-close"
                        type="button"
                        (click)="closeSort()"
                    >
                        <i class="las la-times mobile-sort-actions-close-icon"></i>
                        <span>Close</span>
                    </button>
                </div>
            </div>
        </div>
    }

    @if (selectable === true) {
        <fx-checkbox
            (change)="toggleSelectAll()"
            [checked]="selection.hasValue() && (selectedRowCount | isAllSelected: totalRowsCount)"
            class="fx-table-select-all"
        >
            Select All
        </fx-checkbox>
    }
</div>
@if (enableExpandAll) {
    <button
        (click)="toggleExpandAllRows(); $event.stopPropagation()"
        aria-label="Expand row button"
        class="fx-table-expand-all-button"
    >
        <i class="las la-angle-{{ allRowsExpanded ? 'up' : 'down' }}"></i>
        <span class="fx-table-expand-all-button-label"> {{ allRowsExpanded ? "Collapse" : "Expand" }} all </span>
    </button>
}

@if (displayedColumns$ | async; as displayedColumns) {
    <table
        mat-table
        matSort
        [dataSource]="tableData"
        [multiTemplateDataRows]="true"
        [trackBy]="trackBy"
        [ngClass]="{ 'mat-sort-disabled': !sortEnabled }"
        [matSortDisabled]="!sortEnabled"
        [matSortActive]="sortActive"
        [matSortDirection]="sortDirection"
        (matSortChange)="sortChange($event)"
    >
        <ng-container [matColumnDef]="CHECKBOX_COLUMN_ID">
            <th
                *matHeaderCellDef
                class="v-align-top"
                mat-header-cell
            >
                <fx-checkbox
                    (change)="toggleSelectAll()"
                    [checked]="selection.hasValue() && (selectedRowCount | isAllSelected: totalRowsCount)"
                ></fx-checkbox>
            </th>
            <td
                *matCellDef="let row"
                class="checkbox"
                mat-cell
            >
                <fx-checkbox
                    (change)="checkboxClicked(row, $event)"
                    [checked]="selection.isSelected(row)"
                ></fx-checkbox>
            </td>
        </ng-container>

        @for (column of columns; track column.id) {
            <ng-container [matColumnDef]="column.id">
                <th
                    (click)="analyticsSortClick(column)"
                    *matHeaderCellDef
                    [disabled]="!column.sortable"
                    [ngClass]="{ 'header-align-right': column.alignRight }"
                    [arrowPosition]="column.alignRight ? 'before' : 'after'"
                    mat-header-cell
                    mat-sort-header
                >
                    {{ column.label }}
                    @if (column.info) {
                        <i
                            [tuiHint]="column.info"
                            tuiHintDirection="top"
                            class="las la-question-circle info-icon"
                        >
                        </i>
                    }
                </th>

                <hr class="row-child-divider" />

                <td
                    *matCellDef="let row"
                    [ngClass]="{
                        mobile: column.mobileCollapse,
                        'data-hj-suppress': column.hjSupressData,
                    }"
                    mat-cell
                >
                    <div
                        class="row-template-container"
                        [ngClass]="{
                            'column-align-right': column.alignRight,
                            'expand-button-label': column.expandable,
                        }"
                    >
                        @if (column.expandable) {
                            <button
                                (click)="toggleExpandRow(row); $event.stopPropagation()"
                                aria-label="Expand row button"
                                class="expand-button"
                            >
                                <i class="las la-angle-{{ !row.hideRowChild ? 'up' : 'down' }} expand-button-icon"></i>
                            </button>
                        }

                        <ng-template
                            [ngTemplateOutletContext]="{ $implicit: row }"
                            [ngTemplateOutlet]="column.template"
                        >
                        </ng-template>
                    </div>
                </td>

                @if (enableFooter) {
                    <td
                        *matFooterCellDef
                        mat-footer-cell
                        [ngClass]="{
                            mobile: column.mobileCollapse,
                            'data-hj-suppress': column.hjSupressData,
                            'column-align-right': column.alignRight,
                        }"
                    >
                        @switch (column?.type) {
                            @case ("string") {
                                <span class="mat-footer-column-title"> Total </span>
                            }
                            @case ("currency") {
                                <span class="mat-footer-column-cell">
                                    {{ getTotal(column.id) | currency: column.currency?.toUpperCase() }}
                                </span>
                            }
                            @case ("decimal") {
                                <span class="mat-footer-column-cell">{{ getTotal(column.id) | number }}</span>
                            }
                        }
                    </td>
                }
            </ng-container>
        }

        <ng-container matColumnDef="rowChild">
            <td
                *matCellDef="let row"
                [attr.colspan]="displayedColumns.length"
                mat-cell
            >
                <ng-template
                    [ngTemplateOutletContext]="{ $implicit: row }"
                    [ngTemplateOutlet]="rowChild.template"
                ></ng-template>
            </td>
        </ng-container>

        <!-- Header and Row Declarations -->
        <tr
            *matHeaderRowDef="displayedColumns; sticky: stickyHeader"
            class="header"
            mat-header-row
        ></tr>

        <tr
            (click)="rowClick.emit(row)"
            *matRowDef="let row; columns: displayedColumns"
            [class.selected]="selectedRows.has(row)"
            [ngClass]="{
                'mat-header-row--clickable': rowClickable,
                'mat-row--has-child': rowChild && !row?.hideRowChild,
            }"
            mat-row
        ></tr>

        @if (rowChild) {
            <tr
                *matRowDef="let row; columns: ['rowChild']"
                [hidden]="!!row?.hideRowChild"
                class="row-child"
                mat-row
            ></tr>
        }

        <ng-container *matRowDef="let row; columns: []">
            <hr
                class="row-child-divider"
                [ngClass]="{ 'row-child-divider--has-child': rowChild }"
            />
        </ng-container>

        @if (enableFooter) {
            <tr
                *matFooterRowDef="displayedColumns"
                mat-footer-row
                [attr.colspan]="displayedColumns.length"
            ></tr>
        }
    </table>
}
