import { CommonModule } from "@angular/common";
import { FindexUiModule } from "../findex-ui";
import { FxTableModule } from "../fx-table/fx-table.module";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { NextInstancePipe } from "./pipes/next-instance.pipe";
import { NgModule } from "@angular/core";
import { ParticipantAvatarsModule } from "../threads-ui/modules/participant-avatars/participant-avatars.module";
import { PluginsModule } from "../plugins";
import { PortalUiModule } from "@visoryplatform/portal-ui";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SearchModule } from "../search/search.module";
import { SharedModule } from "../shared";
import { SharedPipesModule } from "../shared/pipes/shared-pipes.module";
import { ThreadsUiModule } from "../threads-ui";
import { TimelineModule } from "../timeline/timeline.module";
import { TuiHintModule } from "@taiga-ui/core";
import { UiLayoutsModule } from "../ui-layouts/ui-layouts.module";
import { WorkflowConfigurationListComponent } from "./components/workflow-configuration-list/workflow-configuration-list.component";
import { WorkflowStatusModule } from "../workflow-status/workflow-status.module";
import { WorkflowVariationsModule } from "../workflow-variations/workflow-variations.module";

@NgModule({
    imports: [
        CommonModule,
        ThreadsUiModule,
        FindexUiModule,
        FxTableModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        SharedPipesModule,
        ParticipantAvatarsModule,
        WorkflowStatusModule,
        WorkflowVariationsModule,
        SharedModule,
        PluginsModule,
        UiLayoutsModule,
        TuiHintModule,
        PortalUiModule,
        MatDatepickerModule,
        FxUiModule,
        SearchModule,
        RouterModule,
        TimelineModule,
    ],
    exports: [WorkflowConfigurationListComponent],
    declarations: [WorkflowConfigurationListComponent, NextInstancePipe],
    providers: [],
})
export class WorkflowConfigurationListModule {}
