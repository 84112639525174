import { Component, Inject, Input, OnChanges, SimpleChanges, Type } from "@angular/core";
import { IThread, Role } from "@visoryplatform/threads";
import { CREATE_CARD_LIBRARY } from "src/app/injection-token";
import { LaunchDarklyFeatureFlags } from "../../../feature-flags";
import { CreateCardExtension } from "../../../plugins/services/Libraries";
import { ExtensionEntry, ILibrary } from "../../../plugins/interfaces/ILibrary";
import { ConnectedPosition } from "@angular/cdk/overlay";
import { DialogService } from "../../../shared/services/dialog.service";
import { DrawerConfig } from "../../../shared/services/drawer.service";
import { MatDialogConfig } from "@angular/material/dialog";
import { AnalyticsService } from "../../../analytics/services/analytics.service";
import { GA_EVENTS } from "../../../analytics/services/gtagAnalytics.service";

@Component({
    selector: "workflow-step-menu",
    templateUrl: "./workflow-step-menu.component.html",
    styleUrls: ["./workflow-step-menu.component.scss"],
})
export class WorkflowStepMenuComponent implements OnChanges {
    @Input() role: Role;
    @Input() thread: IThread;
    readonly FEATURE_FLAGS = LaunchDarklyFeatureFlags;

    cardExtensions: ExtensionEntry<CreateCardExtension>[];
    roles = Role;
    gaEvents = GA_EVENTS;

    contextMenuPosition: ConnectedPosition = {
        originX: "end",
        originY: "bottom",
        overlayX: "end",
        overlayY: "bottom",
    };

    constructor(
        @Inject(CREATE_CARD_LIBRARY) private createCardExtensions: ILibrary<CreateCardExtension>,
        private dialog: DialogService,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        const { thread } = changes;

        if (thread) {
            const restrictCardsToInternal = this.thread.restrictCardsToInternal;
            this.cardExtensions = this.createCardExtensions
                .listAll()
                .filter((extension) => !restrictCardsToInternal || !extension.extension.disableInternalCreation);
        }
    }

    async createCard(component: Type<any>, data: any, config: DrawerConfig | MatDialogConfig): Promise<void> {
        const cardCreateData = {
            ...data,
            thread: this.thread,
            role: this.role,
        };

        await this.dialog
            .open(component, {
                ...config,
                data: cardCreateData,
            })
            .toPromise();
    }

    openContextMenu(isOpen: boolean): void {
        if (isOpen) {
            this.analyticsService.recordEvent("mouse_click", this.gaEvents.WORKFLOW_STEP_VIEW_CONTEXT_MENU);
        }
    }
}
