import { Component, Inject, OnDestroy, OnInit, signal } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Role, ThreadStatus } from "@visoryplatform/threads";
import { Observable, Subscription, Subject } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";
import { GA_EVENTS } from "../../../analytics";
import { AuthService } from "../../../findex-auth";
import { ALL_OPTION, ALL_STATUSES_LABEL } from "../../constants/option-constants";
import { ITimelineFilters } from "../../interfaces/timeline-filters";
import { Loader } from "../../../shared/services/loader";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { Router } from "@angular/router";
import { TableMobileViewControlsService } from "../../../shared/services/table-mobile-view-controls.service";

interface IFormTimelistList {
    filters: FormControl<ITimelineFilters>;
}

@Component({
    selector: "timelines",
    templateUrl: "./timelines.component.html",
    styleUrls: ["./timelines.component.scss"],
    providers: [TableMobileViewControlsService],
})
export class TimelinesComponent implements OnInit, OnDestroy {
    readonly initialFormValues = {
        type: ALL_OPTION,
        status: {
            key: ThreadStatus.active,
            value: this.environment.featureFlags.threadListFilterStatus.active,
        },
        account: ALL_OPTION,
        assignees: ALL_OPTION,
        workflow: ALL_OPTION,
        search: "",
        includeAll: false,
        delphiSort: false,
    };
    readonly defaultFilters = {
        ...this.initialFormValues,
        status: { ...ALL_OPTION, value: ALL_STATUSES_LABEL },
    };

    loader = new Loader();
    form = new FormGroup<IFormTimelistList>({
        filters: new FormControl<ITimelineFilters>(this.initialFormValues),
    });

    globalRole$: Observable<Role>;
    formValues$: Observable<ITimelineFilters>;
    formValuesSub: Subscription;
    activeFilterCount = signal<number>(0);

    protected readonly role = Role;
    protected readonly gaEvents = GA_EVENTS;

    private destroy$ = new Subject<void>();

    constructor(
        private authService: AuthService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private router: Router,
        private tableMobileViewControlsService: TableMobileViewControlsService,
    ) {}

    ngOnInit(): void {
        const user$ = this.authService.getValidUser();

        this.globalRole$ = user$.pipe(
            map((user) => user.globalRole),
            take(1),
        );

        this.formValues$ = this.form.valueChanges.pipe(map((val) => val.filters));
        this.formValuesSub = this.formValues$.pipe(takeUntil(this.destroy$)).subscribe((filterValues) => {
            this.updateQueryParams(filterValues);
        });

        this.setupMobileFilterActions();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    resetFilters(): void {
        this.form.setValue({
            filters: this.initialFormValues,
        });
    }

    private setupMobileFilterActions(): void {
        this.form.valueChanges
            .pipe(
                map((formValues) =>
                    this.tableMobileViewControlsService.getActiveFilterCount(this.defaultFilters, formValues.filters),
                ),
                takeUntil(this.destroy$),
            )
            .subscribe((count) => {
                this.activeFilterCount.set(count);
            });

        this.tableMobileViewControlsService.filterReset$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.resetFilters();
        });
    }

    private updateQueryParams(filterValues: Partial<ITimelineFilters>): void {
        const typeKey = filterValues?.type?.key;
        const statusKey = filterValues?.status?.key || ThreadStatus.active;
        const search = filterValues?.search;
        const account = filterValues?.account?.key;
        const assignees = filterValues?.assignees?.key;
        const workflow = filterValues?.workflow?.key;
        const includeAll = filterValues?.includeAll ?? false;

        void this.router.navigate([], {
            queryParams: {
                type: typeKey || "",
                status: statusKey || "",
                search: search || "",
                account: account || "",
                assignees: assignees || "",
                workflow: workflow || "",
                includeAll: includeAll ?? false,
            },
        });
    }
}
