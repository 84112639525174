<ng-container *ngIf="user$ | async; let user">
    <ng-container *ngIf="card$ | async; let card">
        <ng-container *ngIf="state$ | async; let state">
            <app-card
                *ngIf="card.status === cardStatuses.Removed || card.status === cardStatuses.Deleted"
                [lineCard]="card.status === cardStatuses.Removed || card.status === cardStatuses.Deleted"
                description="This card has been removed."
                [timestamp]="card.modifiedAt"
                [conversation]="false"
            >
            </app-card>
            <thread-card
                title="Conference (BETA)"
                [thread]="thread$ | async"
                [card]="card"
                [replies]="replies$ | async"
                [invertTheme]="user.id === card.createdBy"
                [loading]="!!loader.counter"
                [isInternal]="card.isInternal"
                *ngIf="card.status !== cardStatuses.Removed && card.status !== cardStatuses.Deleted"
            >
                <meeting-details
                    *ngIf="state.sessionKey"
                    (join)="joinSession(user, state)"
                    (terminate)="endSession(cardResources.threadId, cardResources.cardId, state)"
                    [sessionTerminated]="state.isTerminated"
                    [role]="role"
                    [disableEndCall]="false"
                    [status]="state.status"
                    [showJoinCall]="showJoinCall$ | async"
                    [showEndCall]="showEndCall$ | async"
                ></meeting-details>
                <div card-actions>
                    <v-context-menu
                        [gutter]="false"
                        *ngIf="card.createdBy === user.id || (role | permissions: 'RemoveCard' | async)"
                    >
                        <a (click)="deleteCard(cardResources.threadId, cardResources.cardId, state)">Delete</a>
                    </v-context-menu>
                </div>
                <div card-loader>
                    <loader-icon color="light"></loader-icon>
                </div>
                <div
                    class="thread-card-error"
                    *ngIf="errorMessage"
                >
                    {{ errorMessage }}
                </div>
            </thread-card>
        </ng-container>
    </ng-container>
</ng-container>
