<ng-container *ngIf="userId$ | async; let userId">
    <ng-container *ngIf="{ card: card$ | async, thread: thread$ | async }; let context">
        <app-card
            *ngIf="context.card.status === CardStatus.Removed"
            description="This meeting has been moved."
            [timestamp]="context.card.modifiedAt"
            [conversation]="false"
            [threadType]="context.thread.type"
        >
        </app-card>
        <thread-card
            *ngIf="context.card && context.card.status !== CardStatus.Removed"
            class="calendar-card"
            [title]="meetingStatus$ | async"
            [thread]="context.thread"
            [card]="context.card"
            [replies]="replies$ | async"
            [invertTheme]="userId === context.card.createdBy"
            [loading]="!!loader.counter"
            [edited]="context.card.status === CardStatus.Edited"
            [edit]="edit$"
            (save)="save($event)"
            [showDescription]="false"
            [isInternal]="context.card.isInternal"
        >
            <ng-container *ngIf="context.thread | isThreadActive">
                <ng-container *ngIf="invitationCancelled">
                    <p class="alt-meeting-text">This meeting has been cancelled by the organiser.</p>
                </ng-container>
                <ng-container *ngIf="!invitationCancelled && !(invitedToMeeting$ | async)">
                    <p class="alt-meeting-text">
                        {{ notInvitedToMeetingText }}
                    </p>
                </ng-container>
            </ng-container>

            <div class="calendar-card-details">
                <i class="mobile-hide-icon las la-calendar"></i>
                <p class="calendar-card-details-name">{{ meetingName$ | async }}</p>
            </div>

            <div class="calendar-card-details">
                <i class="mobile-hide-icon las la-history"></i>
                <p
                    class="calendar-card-details-schedule-cancelled"
                    *ngIf="invitationCancelled"
                >
                    Cancelled
                </p>
                <p *ngIf="!invitationCancelled && (meetingStatus$ | async) === meetingStatuses.Ended">Meeting Ended</p>
                <ng-container *ngIf="!invitationCancelled && (meetingStatus$ | async) !== meetingStatuses.Ended">
                    <div class="calendar-card-details-schedule">
                        <div class="calendar-card-details-schedule-status">
                            <ng-container *ngIf="start && end && appointmentConfirmed">
                                <p class="calendar-card-details-schedule-date">{{ start | date: "EEEE MMMM d y" }}</p>
                                <p class="calendar-card-details-schedule-time">
                                    {{ start | date: meetingTimeFormat }} - {{ end | date: meetingTimeFormat }}
                                </p>
                            </ng-container>
                            <ng-container *ngIf="!appointmentConfirmed">
                                <p
                                    class="calendar-card-details-schedule-time"
                                    *ngIf="role | permissions: 'CreateCalendarCard' | async"
                                >
                                    A request has been sent. The client can now schedule an appointment using your
                                    availability.
                                </p>
                                <p
                                    class="calendar-card-details-schedule-time"
                                    *ngIf="!(role | permissions: 'CreateCalendarCard' | async)"
                                >
                                    Click "Schedule Meeting" to view available times and book a meeting.
                                </p>
                            </ng-container>
                        </div>
                        <button
                            *ngIf="canRescheduleMeeting$ | async"
                            class="fx-btn fx-btn--primary fx-btn--small calendar-card-details-schedule-button"
                            type="button"
                            [disabled]="loader.counter"
                            (click)="action(CALENDAR_RESCHEDULE_TASK_ACTION_ID)"
                        >
                            RESCHEDULE
                        </button>
                        <button
                            *ngIf="
                                (invitedToMeeting$ | async) &&
                                role === roles.Client &&
                                organiser &&
                                !appointmentConfirmed
                            "
                            class="fx-btn fx-btn--primary fx-btn--small calendar-card-details-schedule-button"
                            type="button"
                            [disabled]="loader.counter"
                            [analyticsClick]="gaEvents.APP_VIEWAVAILABILITY"
                            (click)="action(CALENDAR_SCHEDULE_TASK_ACTION_ID)"
                        >
                            SCHEDULE MEETING
                        </button>
                    </div>
                </ng-container>
            </div>

            <div class="calendar-card-details">
                <i class="las la-sync"></i>
                <div
                    class="calendar-card-details-recurring"
                    *ngIf="(invitation$ | async)?.recurrence; let recurrence; else: norepeat"
                >
                    Repeat:
                    <span>
                        <strong>{{ recurrence.type | titlecase }}, {{ recurrence.numberOfOccurrences }} times</strong>
                    </span>
                </div>
                <ng-template #norepeat>
                    <div class="calendar-card-details-schedule-time">Does not repeat</div>
                </ng-template>
            </div>

            <div
                class="calendar-card-details"
                *ngIf="(avatar$ | async)?.length"
            >
                <i class="las la-user"></i>
                <div class="calendar-card-details-avatars">
                    <fx-avatar
                        *ngFor="let avatar of avatar$ | async; trackBy: 'id' | trackProperty"
                        [name]="avatar.name"
                        [image]="avatar.image"
                        [border]="false"
                        size="small"
                    ></fx-avatar>
                </div>
            </div>

            <div
                class="calendar-card-details"
                *ngIf="invitation$ | async; let invitation"
            >
                <i class="mobile-hide-icon las la-comment"></i>
                <div class="calendar-card-details-agenda">
                    <read-more [showFullDescription]="!(context.thread | isThreadActive)">
                        <quill-view-wrapper
                            [content]="invitation?.message?.description || ''"
                            ariaLabel="message"
                        >
                        </quill-view-wrapper>
                    </read-more>
                </div>
            </div>

            <ng-container *ngIf="!invitationCancelled && vcDetails$ | async as details">
                <div
                    class="meeting-details-container calendar-card-details"
                    [ngClass]="{ 'calendar-card-details--break': details.sessionId }"
                    *ngIf="!details.isTerminated"
                >
                    <meeting-details
                        (join)="openFullscreen(true)"
                        (terminate)="promptAndTerminateSession()"
                        [sessionTerminated]="!details.sessionId"
                        [role]="role"
                        [showJoinCall]="showJoinCall$ | async"
                        [showEndCall]="showEndCall$ | async"
                    ></meeting-details>
                </div>

                <div
                    class="recording-link"
                    *ngIf="
                        (role | permissions: 'DownloadRecording' | async) &&
                        context.card.status !== CardStatus.Disabled &&
                        details.isTerminated
                    "
                >
                    <recording-link
                        *ngIf="details.isTerminated"
                        [threadId]="context.thread?.id"
                        [cardId]="context.card.id"
                        (showLoader)="loader.toggleVisibility($event)"
                    ></recording-link>
                </div>
            </ng-container>

            <div
                card-actions
                *ngIf="(canEditMeeting$ | async) || (canCancelMeeting$ | async) || (canEditOccurence$ | async)"
            >
                <v-context-menu
                    #menu
                    [gutter]="false"
                >
                    <a
                        *ngIf="canEditMeeting$ | async"
                        (click)="openMeetingRequestModal(context.thread, true)"
                        [analyticsClick]="gaEvents.APP_MEETING_EDIT_ATTENDEES"
                    >
                        Edit attendees
                    </a>

                    <a
                        *ngIf="canEditOccurence$ | async"
                        (click)="openInstanceModal()"
                        [analyticsClick]="gaEvents.APP_MEETING_EDIT_OCCURENCE"
                    >
                        Edit Occurrence
                    </a>

                    <a
                        *ngIf="canCancelMeeting$ | async"
                        (click)="cancelMeeting(context.thread?.id, context.card.id)"
                        [analyticsClick]="gaEvents.APP_MEETING_EDIT_CANCEL"
                    >
                        Cancel meeting
                    </a>
                </v-context-menu>
            </div>
            <div card-loader>
                <loader-icon color="light"></loader-icon>
            </div>
            <div
                class="thread-card-error"
                *ngIf="errorMessage"
            >
                {{ errorMessage }}
            </div>
        </thread-card>
    </ng-container>
</ng-container>
