import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    FormRecord,
    NG_VALUE_ACCESSOR,
    Validators,
} from "@angular/forms";
import { DueDateAnchor, IThreadListing, ITimeline, IWorkflowConfigurationSteps } from "@visoryplatform/threads";
import { DateTime } from "luxon";
import { IStepUI } from "../../../../interfaces/IStepUi";
import { StepDataForm } from "../../../create-thread/types/StepDataForm";
import { Subscription } from "rxjs";
import { WorkflowStepsService } from "../../../../services/workflow/workflow-steps.service";
import { calculationPoints } from "./due-dates.constants";
import { IWorkflow, IWorkflowDesign, CREATE_END_HOUR, WorkflowGraphService } from "@visoryplatform/workflow-core";

export type EditWorkflowInput = {
    calculateFromDate: DateTime;
    datesCalculationPoint: DueDateAnchor;
};

export interface CalculationPoint {
    key: string;
    value: DueDateAnchor;
}

@Component({
    selector: "edit-workflow-config",
    templateUrl: "./edit-workflow-config.component.html",
    styleUrls: ["./edit-workflow-config.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: EditWorkflowConfigComponent,
        },
    ],
})
export class EditWorkflowConfigComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
    @Input() defaultThread?: IThreadListing | ITimeline;
    @Input() workflowConfigurationSteps?: IWorkflowConfigurationSteps;
    @Input() stepConfig: FormRecord<FormGroup<StepDataForm>>;
    @Input() timeZone: string;
    @Input() workflow: IWorkflow | IWorkflowDesign;
    @Input() canUpdateAssignees: boolean;
    @Input() canEditDueDates = false;

    @Output() calculationFromTimeChanged = new EventEmitter<void>();
    @Output() resetDatesClicked = new EventEmitter<void>();
    @Output() resetToTemplateClicked = new EventEmitter<void>();

    readonly calculateFromPlaceholders = {
        start: "Select start date",
        end: "Select end date",
    };

    calculateFromDate = new FormControl<DateTime>(null, [Validators.required]);
    datesCalculationPoint = new FormControl<DueDateAnchor>(null, [Validators.required]);
    formGroup = new FormGroup({
        calculateFromDate: this.calculateFromDate,
        datesCalculationPoint: this.datesCalculationPoint,
    });

    datesCalculationPointOptions = DueDateAnchor;
    calculationPointOptions = this.getCalculationPointOptions();
    stepsUI: IStepUI[];
    formSub: Subscription;

    onChange?: (val: Partial<EditWorkflowInput>) => void;
    onTouch?: () => void;

    constructor(private workflowStepsService: WorkflowStepsService) {}

    ngOnInit(): void {
        this.formSub = this.formGroup.valueChanges.subscribe((val) => {
            const timezoneAdjusted = val.calculateFromDate?.setZone(this.timeZone, { keepLocalTime: true });
            const timeAdjusted = timezoneAdjusted?.set({ hour: CREATE_END_HOUR });

            const output = {
                calculateFromDate: timeAdjusted,
                datesCalculationPoint: val.datesCalculationPoint,
            };

            this.onChange?.(output);
            this.onTouch?.();
        });
    }

    ngOnDestroy(): void {
        this.formSub?.unsubscribe();
    }

    writeValue(val: EditWorkflowInput): void {
        this.formGroup.patchValue(val);
    }

    registerOnChange(fn: (val: EditWorkflowInput) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.calculateFromDate.disable();
            this.datesCalculationPoint.disable();
        } else {
            this.calculateFromDate.enable();
            this.datesCalculationPoint.enable();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.canUpdateAssignees || changes.stepConfig) {
            for (const control of Object.values(this.stepConfig?.controls || {})) {
                if (this.canUpdateAssignees) {
                    control?.controls.assignees?.enable();
                } else {
                    control?.controls.assignees?.disable();
                }
            }
        }

        if (changes.workflow && this.workflow) {
            const steps = WorkflowGraphService.orderWorkflowSteps(this.workflow);
            this.stepsUI = this.workflowStepsService.getStepsUI(steps);
        }

        if (changes.workflowConfigurationSteps && this.workflowConfigurationSteps && this.workflow) {
            const steps = WorkflowGraphService.orderWorkflowSteps(this.workflow);
            this.stepsUI = this.workflowStepsService.getStepsUI(steps, this.workflowConfigurationSteps);
        }
    }

    resetDates(): void {
        this.resetDatesClicked.emit();
    }

    calculateDatesOnFromDateChanged(): void {
        if (this.calculateFromDate?.valid) {
            this.calculationFromTimeChanged.emit();
        }
    }

    resetToTemplate(): void {
        this.resetToTemplateClicked.emit();
    }

    private getCalculationPointOptions(): Array<{ value: string; label: string }> {
        return calculationPoints.map((point) => ({
            value: point.value,
            label: point.key,
        }));
    }
}
