<loader-icon
    *ngIf="loader.counter"
    color="light"
></loader-icon>

<div class="payrun-action-request-summary-title">
    <div class="payrun-action-request-summary-title-group">
        <h3 class="payrun-action-request-summary-title-heading">Pay run summary</h3>
        <div class="payrun-action-request-summary-title-desc">
            Review employee details and approve pay run information. Add comments to request changes
        </div>
    </div>
    <div class="payrun-action-request-summary-title-buttons">
        <button
            *ngIf="role | permissions: 'PayrunRequestRefresh' : 'ThreadUpdateAll' | async"
            [analyticsClick]="gaEvents.PAYRUN_REFRESH"
            class="fx-btn fx-btn--primary-outlined"
            type="button"
            (click)="refreshPayrunData()"
        >
            <i class="las la-sync"></i>
            REFRESH REPORT
        </button>
    </div>
</div>

<ng-container *ngIf="report$ | async as report; else showLoader">
    <ng-container *ngIf="tableData$ | async as tableData">
        <div class="threads table-container">
            <payrun-request-table
                [report]="report"
                [allowEdit]="false"
                [tableData]="tableData"
            ></payrun-request-table>
        </div>
    </ng-container>
</ng-container>

<ng-template #showLoader>
    <div class="report-ghost">
        <ghost-loader
            [showAnimation]="true"
            [width]="100"
            shape="square"
        ></ghost-loader>
        <ghost-loader
            [showAnimation]="true"
            [width]="100"
            shape="square"
        ></ghost-loader>
        <ghost-loader
            [showAnimation]="true"
            [width]="100"
            shape="square"
        ></ghost-loader>
        <ghost-loader
            [showAnimation]="true"
            [width]="100"
            shape="square"
        ></ghost-loader>
        <ghost-loader
            [showAnimation]="true"
            [width]="100"
            shape="square"
        ></ghost-loader>
    </div>
</ng-template>
