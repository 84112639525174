import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "menu-item",
    templateUrl: "./menu-item.component.html",
    styleUrls: ["./menu-item.component.scss"],
})
export class MenuItemComponent {
    @Input() icon: string;
    @Input() route?: string[];
    @Input() exact = false;
    @Input() isExternalLink?: boolean;
    @Input() counter?: number;

    @Output() click = new EventEmitter<any>();
}
