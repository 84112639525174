import { Component, Inject, Input, OnDestroy } from "@angular/core";
import { ITimeline, Role } from "@visoryplatform/threads";
import { Loader } from "../../../shared/services/loader";
import { IWorkflow, IWorkflowAction } from "@visoryplatform/workflow-core";
import { AnalyticsService, GA_EVENTS_PREFIX } from "../../../analytics";
import { WorkflowExtensionService } from "../../../threads-ui/services/workflow/workflow-extension.service";
import { MenuService, MenuType } from "../../../shared/services/menu.service";
import { DrawerService } from "../../../shared/services/drawer.service";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import {
    EditThreadModalComponent,
    EditThreadModalData,
} from "../../../threads-ui/modules/create-thread/components/edit-thread-modal/edit-thread-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { AlertService } from "@visoryplatform/portal-ui";
import { ToastMessage, ToastSeverity, ToastSummary } from "../../../shared/constants/toast.constants";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { WindowListenersService } from "../../../shared/services/window-listeners.service";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";

@Component({
    selector: "workflow-details",
    templateUrl: "./workflow-details.component.html",
    styleUrls: ["./workflow-details.component.scss"],
})
export class WorkflowDetailsComponent implements OnDestroy {
    @Input() role: Role;
    @Input() thread: ITimeline;

    loader = new Loader();
    showWorkflowList$: Observable<boolean>;
    isMobileView: boolean;
    drawerVisible$: Observable<boolean>;

    routeSubscription: Subscription;

    constructor(
        private workflowExtensionService: WorkflowExtensionService,
        private analyticsService: AnalyticsService,
        private menuService: MenuService,
        private dialog: MatDialog,
        private drawerService: DrawerService,
        private alertService: AlertService,
        private router: Router,
        private windowListenersService: WindowListenersService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {
        this.showWorkflowList$ = menuService.showWorkflowList();
        this.isMobileView = this.getIsMobileView();
        this.toggleWorkflowListOnRouteChanges();
        this.drawerVisible$ = this.drawerService.item$.pipe(map((item) => !!item));
    }

    ngOnDestroy(): void {
        this.routeSubscription?.unsubscribe();
    }

    toggleWorkflowList(show: boolean): void {
        if (show) {
            this.menuService.show(MenuType.WorkflowList);
        } else {
            this.menuService.hide(MenuType.WorkflowList);
        }
    }

    editDates(thread: ITimeline, globalRole: Role): void {
        const config = {
            data: { thread, role: globalRole },
            disableClose: true,
            panelClass: ["modal-container", "threads-sidebar", "mat-dialog-no-styling"],
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
        };

        this.dialog
            .open<EditThreadModalComponent, EditThreadModalData>(EditThreadModalComponent, config)
            .afterClosed()
            .subscribe((updated) => {
                if (!updated) {
                    return;
                }
                this.showSuccessToast(ToastMessage.TimelineUpdated);
            });
    }

    showWorkflowList(): void {
        this.menuService.hide(MenuType.WorkflowList);
    }

    async performAction(thread: ITimeline, role: Role, workflow: IWorkflow, action: IWorkflowAction): Promise<void> {
        console.log("Performing action", workflow, action);
        this.loader.show();
        try {
            await this.workflowExtensionService.handleAction(thread, role, workflow, workflow.currentStepId, action.id);
            this.analyticsService.recordEvent(GA_EVENTS_PREFIX.THREADS, action.label);
        } finally {
            this.loader.hide();
        }
    }

    private showSuccessToast(message: ToastMessage): void {
        this.alertService
            .show({
                status: ToastSeverity.Success,
                label: ToastSummary.Success,
                message,
            })
            .subscribe();
    }

    private toggleWorkflowListOnRouteChanges(): void {
        if (this.isMobileView) {
            this.routeSubscription = this.router.events
                .pipe(filter((event) => event instanceof NavigationEnd))
                .subscribe(() => {
                    this.toggleWorkflowList(false);
                });
        }
    }

    private getIsMobileView(): boolean {
        return this.windowListenersService.isWindowSmaller(this.environment.featureFlags.windowWidthTabletBreakpoint);
    }
}
